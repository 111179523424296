import React from 'react'
import { motion } from 'framer-motion'

import './Down-angle-inner-page.sass'

const variantsSection = {
  hidden: {
    y: '-6vh'
  },
  visible: {
    y: 0,
    transition: {
      duration: 0.8, type: 'tween'
    }
  }
}

const variantsDownAngle = {
  hidden: {
    borderBottomWidth: '1vw'
  },
  visible: {
    borderBottomWidth: '20vw',
    transition: {
      duration: 0.8, type: 'tween'
    }
  }
}

const variantsTitle = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5
    }
  }
}

const DownAngleInnerPage = (props) => {
  return (
    <motion.section
      className="image-section"
      variants={variantsSection}
      initial="hidden"
      animate="visible"
    >
      <motion.div
        className="page-title"
        variants={variantsTitle}
      >
        {props.title}
      </motion.div>
      <div className="bg-img bg-overlay dark" />
      <div className="shows-over-fixed">
        <div className="down-angle-container">
          <motion.div
            className="down-angle down-angle-1"
            variants={variantsDownAngle}
          />
          <motion.div
            className="down-angle down-angle-2"
            variants={variantsDownAngle}
          />
        </div>
      </div>
    </motion.section>

  )
}

export default DownAngleInnerPage
