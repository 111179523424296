import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import {
  Button,
  Collapse,
  Step,
  StepButton,
  Stepper,
  TextField,
  Tooltip,
  Zoom,
} from "@mui/material";

import SwipeableViews from "react-swipeable-views";

import {
  AppBar,
  Alert,
  AlertTitle,
  Pagination,
  Box,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import HomeIcon from "@mui/icons-material/Home";
import ReplyIcon from "@mui/icons-material/Reply";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DoneIcon from "@mui/icons-material/Done";

import * as yup from "yup";
import { useFormik } from "formik";
import useSWR from "swr";
import { FormattedMessage } from "react-intl";

import Bullhorn from "../../../../../assets/image/svg/bullhorn-ico.svg";
import environment from "../../../../../environments/environment";
import { Context } from "../../../../contexts/Context";
import fetcher from "../../../../../rest/fetcher";
import CivilInitiatives from "../../../../items/civil-initiatives/Civil-initiatives";
import DownAngleInnerPage from "../../../../pages-inner/down-angle-inner-page/Down-angle-inner-page";
import SceletonCivilInitiatives from "../../../../sceletons/Sceleton-civil-initiatives";
import "./Page-civil-initiatives.sass";

const variantsSection = {
  hidden: {
    opacity: 0,
    y: "20vh",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
    },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const getSteps = () => {
  return [
    <FormattedMessage id={"actual-idea"} />,
    <FormattedMessage id={"sending-request"} />,
    <FormattedMessage id={"project-creation"} />,
  ];
};
function getStepContent(step) {
  switch (step) {
    case 0:
      return (
        <span style={{ color: "var(--blue-dark)" }}>
          <strong style={{ color: "var(--orange-dark)" }}>
            <FormattedMessage id={"step"} /> 1:{" "}
          </strong>
          <FormattedMessage id={"actual-idea-description"} />
        </span>
      );
    case 1:
      return (
        <span style={{ color: "var(--blue-dark)" }}>
          <strong style={{ color: "var(--orange-dark)" }}>
            <FormattedMessage id={"step"} /> 2:{" "}
          </strong>
          <FormattedMessage id={"sending-request-description"} />
        </span>
      );
    case 2:
      return (
        <>
          <span style={{ color: "var(--blue-dark)" }}>
            <strong style={{ color: "var(--orange-dark)" }}>
              <FormattedMessage id={"step"} /> 3:{" "}
            </strong>
            <FormattedMessage id={"project-creation-description"} />
            <DoneIcon className="done-icon" />
          </span>
        </>
      );
    default:
      return "Unknown step";
  }
}

const PageCivilInitiatives = () => {
  const context = useContext(Context);

  const [activeStep, setActiveStep] = useState(0);
  const [completed] = useState({});
  const steps = getSteps();
  const totalSteps = () => {
    return steps.length;
  };
  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  let navigate = useNavigate();
  const url =
    environment.cms_headless_url +
    "/api/rest/node?_format=json&field_type_page_target_id=Гражданские инициативы&langcode=";
  const { data, error } = useSWR(
    context.locale === "en-US" ? url + "en" : url + "ru",
    fetcher
  );

  const [itemsCivilInitiatives, setItemsCivilInitiatives] = useState([]);
  const [currItem, setCurrItem] = useState(1);
  const [itemsPer] = useState(8);

  // Get current items
  const indexOfLast = currItem * itemsPer;
  const indexOfFirst = indexOfLast - itemsPer;
  const currItemsCivilInitiatives = itemsCivilInitiatives.slice(
    indexOfFirst,
    indexOfLast
  );

  // Change page CivilInitiatives
  const handleChangeCivilInitiatives = (event, value) => {
    setCurrItem(value);
  };

  function goBack() {
    navigate(-1);
  }

  const validationSchema = yup.object().shape({
    name: yup
      .string(<FormattedMessage id={"name.required-1"} />)
      .min(4, <FormattedMessage id={"name.required-2"} />)
      .required(<FormattedMessage id={"name.required-3"} />),
    email: yup
      .string(<FormattedMessage id={"email.required-1"} />)
      .email(<FormattedMessage id={"email.required-2"} />)
      .required(<FormattedMessage id={"email.required-3"} />),
    message: yup
      .string(<FormattedMessage id={"text.required-1"} />)
      .min(25, <FormattedMessage id={"text.required-2"} />)
      .required(<FormattedMessage id={"text.required-3"} />),
  });

  const [open, setOpen] = useState(false);
  let scetetonList = [];
  for (let i = 0; i < 4; i++) {
    scetetonList.push(<SceletonCivilInitiatives key={i} />);
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const xhr = new XMLHttpRequest();
      xhr.addEventListener("load", () => {
        if (xhr.responseText !== null) {
          if (xhr.status === 200) {
            const interval = setInterval(() => {
              setOpen(false);
            }, 5000);
            return () => {
              clearInterval(interval);
            };
          }
        }
      });
      xhr.open(
        "GET",
        environment.emailUrl +
          "?sendto=" +
          values.email +
          "&name=" +
          values.name +
          "&message=" +
          values.message
      );
      xhr.send();
      formik.resetForm();
      setOpen(true);
    },
  });

  useEffect(() => {
    if (data) {
      setItemsCivilInitiatives(data);
    }
  }, [data]);

  useEffect(() => {
    document.body.scrollIntoView({ behavior: "auto", block: "start" });
  }, []);

  if (error) return console.log(error);
  return (
    <>
      <DownAngleInnerPage
        title={<FormattedMessage id={"civil-initiatives"} />}
      />
      <motion.section
        className="civil-initiatives"
        variants={variantsSection}
        initial="hidden"
        animate="visible"
      >
        <Container>
          <section className="content text-center mt-0 mb-5">
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  label={
                    <span className="label">
                      <AssignmentIcon className="me-2" />
                      <FormattedMessage id={"about-project"} />
                    </span>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    <span className="label">
                      <MailOutlineIcon className="me-2" />
                      <FormattedMessage id={"send-request"} />
                    </span>
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  label={
                    <span className="label">
                      <BeenhereIcon className="me-2" />
                      <FormattedMessage id={"in-work"} />
                    </span>
                  }
                  {...a11yProps(2)}
                />
              </Tabs>
            </AppBar>
            <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
              <TabPanel value={value} index={0}>
                <img
                  data-src={Bullhorn}
                  alt="Civil initiatives"
                  className="svg-icon wrap-circle mb-2"
                  width="60"
                  height="50"
                  src={Bullhorn}
                />
                <h2>
                  <FormattedMessage id={"civil-initiatives"} />
                </h2>
                <p className="mt-4">
                  <FormattedMessage id={"civil-initiatives-proclamation"} />
                </p>
                <div className={"d-none d-sm-block d-sm-none d-md-block mt-4"}>
                  <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                      <Step key={index}>
                        <StepButton
                          onClick={handleStep(index)}
                          completed={completed[index]}
                        >
                          {label}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>

                  <div className="mt-2">
                    <Typography>{getStepContent(activeStep)}</Typography>
                    <div className="mt-4">
                      <Button
                        className="me-1"
                        variant="contained"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                      >
                        <FormattedMessage id={"prev"} />
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        <FormattedMessage id={"next"} />
                      </Button>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Row>
                  <Col className="col-3 left d-none d-sm-block d-sm-none d-md-block" />
                  <Col>
                    <Collapse in={open}>
                      <Alert
                        severity="success"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <AlertTitle>
                          <FormattedMessage id={"alert-mail.success"} />
                        </AlertTitle>
                      </Alert>
                    </Collapse>
                    <form onSubmit={formik.handleSubmit}>
                      <TextField
                        fullWidth
                        id="name"
                        className="mb-2"
                        name="name"
                        label={<FormattedMessage id={"name.required-1"} />}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                      <TextField
                        fullWidth
                        id="email"
                        className="mb-2"
                        name="email"
                        label={<FormattedMessage id={"email.required-1"} />}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                      <TextField
                        fullWidth
                        multiline
                        id="message"
                        name="message"
                        label={<FormattedMessage id={"text.required-1"} />}
                        value={formik.values.message}
                        rows={7}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.message &&
                          Boolean(formik.errors.message)
                        }
                        helperText={
                          formik.touched.message && formik.errors.message
                        }
                      />
                      <Button
                        className="mt-2"
                        fullWidth
                        color="primary"
                        variant="contained"
                        type="submit"
                      >
                        <FormattedMessage id={"send"} />
                      </Button>
                    </form>
                  </Col>
                  <Col className="col-3 left d-none d-sm-block d-sm-none d-md-block" />
                </Row>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <section className="content text-center mt-0 mb-5">
                  {data ? (
                    <Row>
                      {currItemsCivilInitiatives.map((data) => (
                        <CivilInitiatives key={data.nid} data={data} />
                      ))}
                      <div style={{ width: "100%" }} />
                      {data.length > 8 && (
                        <Pagination
                          className="mt-3"
                          count={Math.ceil(itemsCivilInitiatives.length / 8)}
                          color="primary"
                          onChange={handleChangeCivilInitiatives}
                          showFirstButton
                          showLastButton
                          shape="rounded"
                        />
                      )}
                    </Row>
                  ) : (
                    <Row>{scetetonList}</Row>
                  )}
                </section>
              </TabPanel>
            </SwipeableViews>
          </section>
          <div className="button-group mt-4 mb-4 text-center">
            <Tooltip
              title={context.locale === "ru-RU" ? "На главную" : "To the main"}
              TransitionComponent={Zoom}
              arrow
            >
              <button>
                <Link to={"/"}>
                  <HomeIcon color="primary" />
                </Link>
              </button>
            </Tooltip>
            <Tooltip
              title={
                context.locale === "ru-RU" ? "На предыдущую" : "To the previous"
              }
              TransitionComponent={Zoom}
              arrow
            >
              <button onClick={() => goBack()}>
                <ReplyIcon color="primary" />
              </button>
            </Tooltip>
          </div>
        </Container>
      </motion.section>
    </>
  );
};

export default PageCivilInitiatives;
