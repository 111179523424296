import React from "react";
import { Col } from "react-bootstrap";

import { Box, Skeleton, Avatar, Typography } from "@mui/material";

const SceletonProject = () => {
  return (
    <>
      <Col lg={3} sm={6} md={6} col={6} xs={6} className={"mb-5"}>
        <Box display="flex" alignItems="center" className={"mb-1"}>
          <Box margin={1}>
            <Skeleton variant="circle" animation={"wave"}>
              <Avatar />
            </Skeleton>
          </Box>
          <Box width="100%">
            <Skeleton animation={"wave"} width="100%">
              <Typography>.</Typography>
            </Skeleton>
            <Skeleton animation={"wave"} height={10} width={"30%"}>
              <Typography>.</Typography>
            </Skeleton>
          </Box>
        </Box>
        <Skeleton
          className={"mb-2"}
          variant="rect"
          animation={"wave"}
          width="100%"
        >
          <div style={{ paddingTop: "50%" }} />
        </Skeleton>
        <Skeleton
          animation={"wave"}
          height={10}
          width={"50%"}
          style={{ left: "50%", marginLeft: "-25%" }}
        />
        <Skeleton
          animation={"wave"}
          height={10}
          width={"75%"}
          style={{ left: "50%", marginLeft: "-38%" }}
        />
        <Skeleton
          animation={"wave"}
          height={10}
          width={"50%"}
          style={{ left: "50%", marginLeft: "-25%" }}
        />
        <Skeleton
          animation={"wave"}
          height={10}
          width={"75%"}
          style={{ left: "50%", marginLeft: "-38%" }}
        />
        <Skeleton
          animation={"wave"}
          height={10}
          width={"50%"}
          style={{ left: "50%", marginLeft: "-25%" }}
        />
        <Box
          className={"mt-1"}
          style={{
            flexBasis: "100%",
            display: "flex",
            flexGrow: 1,
            textAlign: "center",
          }}
        >
          <Skeleton
            style={{ flex: "1 1 100px", marginRight: "30%" }}
            animation={"wave"}
          />
          <Skeleton
            style={{ flex: "1 1 10px", marginRight: "2%" }}
            animation={"wave"}
          />
          <Skeleton style={{ flex: "1 1 10px" }} animation={"wave"} />
        </Box>
      </Col>
    </>
  );
};

export default SceletonProject;
