import React, { useContext, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { slugify } from "transliteration";
import axios from "axios";
import { useFormik } from "formik";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import {
  Card,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Tooltip,
  TextField,
  Button,
  Icon,
  CardMedia,
  IconButton,
  Zoom,
  Alert,
  AlertTitle,
} from "@mui/material";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

import environment from "../../../environments/environment";
import { Context } from "../../contexts/Context";
import DownAngleInnerPage from "../../pages-inner/down-angle-inner-page/Down-angle-inner-page";
import Image from "../../../assets/image/backg-user-reg.jpg";
import restUploadFile from "../../../rest/reg&oauth2/rest-upload-file";
import restUpdateUser from "../../../rest/reg&oauth2/rest-update-user";
import restGetUserInfo from "../../../rest/reg&oauth2/rest-get-user-info";
import restRefreshToken from "../../../rest/reg&oauth2/rest-refresh-token";
import restDeleteUser from "../../../rest/reg&oauth2/rest-delete-user";
import "./User-account.sass";

const UserAccount = () => {
  const { id } = useParams();
  const context = useContext(Context);
  const [errorMessage, setErrorMessage] = useState(null);
  const [saveStatus, setSaveStatus] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [fileImage, setFileImage] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [tokenCSRF, setTokenCSRF] = useState(null);
  const [progressShow, setProgressShow] = useState(false);
  const [popupShow, setPopupShow] = useState(false);
  const currDate = new Date();

  let accessToken = context.accessToken;
  let refreshToken = context.refreshToken;
  let accessTokenExpirationTime;
  let fid = context.FID;
  let uid = id;
  let urlUserPicture = context.urlUserPicture;
  let userMail = context.userMail;
  let userName = context.userName;
  let surName = context.surName;
  let firstName = context.firstName;
  let middleName = context.middleName;
  let statusCheckboxnl = context.statusCheckboxnl;
  let imgFileName = "";

  useEffect(() => {
    axios.get(environment.cms_headless_url + "/session/token").then((res) => {
      setTokenCSRF(res.data);
    });
    getUserInfo(uid);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (errorStatus) {
      setSaveStatus(false);
      setTimeout(() => {
        setErrorStatus(false);
      }, 8000);
    }
  }, [errorStatus, saveStatus]);

  useEffect(() => {
    if (saveStatus && !errorStatus) {
      setTimeout(() => {
        setSaveStatus(false);
      }, 3000);
    }
    // eslint-disable-next-line
  }, [saveStatus]);

  const getUserInfo = (uid) => {
    restGetUserInfo(accessToken, uid).then((res) => {
      let resObj = JSON.parse(res);
      userMail = resObj["field_mail_for_distribution"]
        ? resObj["field_mail_for_distribution"][0].value
        : "";
      context.setUserMail(userMail);
      userName = resObj["name"] ? resObj["name"][0].value : "";
      context.setUserName(userName);
      surName = resObj["field_sur_name"]
        ? resObj["field_sur_name"][0].value
        : "";
      context.setSurName(surName);
      firstName = resObj["field_first_name"]
        ? resObj["field_first_name"][0].value
        : "";
      context.setFirstName(firstName);
      middleName = resObj["field_middle_name"]
        ? resObj["field_middle_name"][0].value
        : "";
      context.setMiddleName(middleName);
      statusCheckboxnl = resObj["field_statuscheckboxnl"]
        ? resObj["field_statuscheckboxnl"][0].value
        : false;
      context.setStatusCheckboxnl(statusCheckboxnl);

      localStorage.setItem("userMail", JSON.stringify(userMail));
      localStorage.setItem("userName", JSON.stringify(userName));
      localStorage.setItem("surName", JSON.stringify(surName));
      localStorage.setItem("firstName", JSON.stringify(firstName));
      localStorage.setItem("middleName", JSON.stringify(middleName));
      localStorage.setItem(
        "statusCheckboxnl",
        JSON.stringify(statusCheckboxnl)
      );

      if (resObj["_embedded"]) {
        urlUserPicture =
          resObj["_embedded"][
            "https://cms-headless.fund-dm.ru/rest/relation/user/user/user_picture"
          ][0]["_links"]["self"]["href"];
      } else {
        urlUserPicture = environment.userPictureDefaultUrl;
      }
      context.setUrlUserPicture(urlUserPicture);
      localStorage.setItem("urlUserPicture", JSON.stringify(urlUserPicture));
    });
  };

  const getRefreshToken = async () => {
    let FormData = require("form-data");
    let data = new FormData();
    data.append("grant_type", "refresh_token");
    data.append("refresh_token", refreshToken);
    data.append("client_id", environment.client_id_webapp);
    data.append("client_secret", environment.client_secret_webapp);

    await restRefreshToken(data).then((res) => {
      let resObj = JSON.parse(res);
      accessTokenExpirationTime = new Date(
        currDate.setSeconds(currDate.getSeconds() + resObj.expires_in)
      );

      accessToken = resObj["access_token"];
      refreshToken = resObj["refresh_token"];
      context.setAccessToken(accessToken);
      context.setRefreshToken(refreshToken);
      context.setAccessTokenExpirationTime(accessTokenExpirationTime);

      localStorage.setItem("accessToken", JSON.stringify(accessToken));
      localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
      localStorage.setItem(
        "accessTokenExpirationTime",
        JSON.stringify(accessTokenExpirationTime)
      );
    });
  };

  const handleImageChange = async (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    setFileImage(file);

    if (
      file &&
      file.size < 524288 &&
      (file.type === "image/png" || file.type === "image/jpeg")
    ) {
      reader.onloadend = () => {
        setImageData(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setErrorStatus(true);
      setErrorMessage(
        <FormattedMessage
          id={"error-size-file-avatar"}
          values={{ size: "512", nameType: "jpeg, png" }}
        />
      );
      setImageData(null);
      setFileImage(null);
    }
  };

  const uploadFile = async () => {
    if (imageData) {
      await restUploadFile(fileImage, tokenCSRF, accessToken, imgFileName)
        .then((res) => {
          let resObj = JSON.parse(res);
          fid = resObj["fid"][0].value;
          context.setFID(fid);
          localStorage.setItem("FID", JSON.stringify(fid));
        })
        .catch((error) => {
          setErrorStatus(true);
          console.log(error);
        });
    }
  };

  const updateUser = async () => {
    let dataWithImage = {
      _links: {
        type: {
          href: "https://cms-headless.fund-dm.ru/rest/type/user/user",
        },
      },
      user_picture: [
        {
          target_id: fid,
          description: "User picture",
        },
      ],
      name: [{ value: formik.values.nameUser }],
      field_sur_name: [{ value: formik.values.surName }],
      field_first_name: [{ value: formik.values.firstName }],
      field_middle_name: [{ value: formik.values.middleName }],
      field_statuscheckboxnl: [{ value: formik.values.statusCheckboxNL }],
      field_mail_for_distribution: [{ value: formik.values.email }],
    };
    let dataWithoutImage = {
      _links: {
        type: {
          href: "https://cms-headless.fund-dm.ru/rest/type/user/user",
        },
      },
      name: [{ value: formik.values.nameUser }],
      field_sur_name: [{ value: formik.values.surName }],
      field_first_name: [{ value: formik.values.firstName }],
      field_middle_name: [{ value: formik.values.middleName }],
      field_statuscheckboxnl: [{ value: formik.values.statusCheckboxNL }],
      field_mail_for_distribution: [{ value: formik.values.email }],
    };

    let data;
    imageData ? (data = dataWithImage) : (data = dataWithoutImage);

    await restUpdateUser(data, tokenCSRF, accessToken, id)
      .then((res) => {
        if (res && res.status === 422) {
          setErrorStatus(true);
          setErrorMessage(
            <FormattedMessage id={"registration-error422-message1"} />
          );
        } else {
          let resObj = JSON.parse(res);
          userMail = resObj["field_mail_for_distribution"]
            ? resObj["field_mail_for_distribution"][0].value
            : "";
          userName = resObj["name"] ? resObj["name"][0].value : "";
          surName = resObj["field_sur_name"]
            ? resObj["field_sur_name"][0].value
            : "";
          firstName = resObj["field_first_name"]
            ? resObj["field_first_name"][0].value
            : "";
          middleName = resObj["field_middle_name"]
            ? resObj["field_middle_name"][0].value
            : "";
          statusCheckboxnl = resObj["field_statuscheckboxnl"]
            ? resObj["field_statuscheckboxnl"][0].value
            : false;

          if (resObj["_embedded"]) {
            urlUserPicture =
              resObj["_embedded"][
                "https://cms-headless.fund-dm.ru/rest/relation/user/user/user_picture"
              ][0]["_links"]["self"]["href"];
          } else {
            urlUserPicture = environment.userPictureDefaultUrl;
          }

          context.setUrlUserPicture(urlUserPicture);
          context.setUserMail(userMail);
          context.setUserMailing(userMail);
          context.setUserName(userName);
          context.setSurName(surName);
          context.setFirstName(firstName);
          context.setMiddleName(middleName);
          context.setStatusCheckboxnl(statusCheckboxnl);

          localStorage.setItem(
            "urlUserPicture",
            JSON.stringify(urlUserPicture)
          );
          localStorage.setItem("userMail", JSON.stringify(userMail));
          localStorage.setItem("userMailing", JSON.stringify(userMail));
          localStorage.setItem("userName", JSON.stringify(userName));
          localStorage.setItem("surName", JSON.stringify(surName));
          localStorage.setItem("firstName", JSON.stringify(firstName));
          localStorage.setItem("middleName", JSON.stringify(middleName));
          localStorage.setItem(
            "statusCheckboxnl",
            JSON.stringify(statusCheckboxnl)
          );
        }
      })
      .catch((error) => {
        setErrorStatus(true);
        console.log(error);
      });
  };

  const deleteUserAccount = async () => {
    setProgressShow(true);
    await restDeleteUser(accessToken, uid)
      .then(() => {
        setDeleteStatus(true);
        setProgressShow(false);
        formik.resetForm();
        setTimeout(() => {
          setDeleteStatus(false);
          logout();
          <Navigate to="/" replace />;
        }, 1500);
      })
      .catch((error) => {
        setErrorStatus(true);
        setDeleteStatus(true);
        console.log(error);
      });
  };

  const logout = () => {
    if (context.userAuthenticated) {
      context.setUserAuthenticated(false);
      localStorage.setItem("userAuthenticated", JSON.stringify(false));
    }

    context.setUID(null);
    context.setFID(null);
    context.setAccessToken(null);
    context.setRefreshToken(null);
    context.setAccessTokenExpirationTime(null);
    context.setCurrentProvider(null);
    context.setUrlUserPicture(null);
    context.setUserMail(null);
    context.setUserMailing(null);
    context.setUserName(null);
    context.setSurName(null);
    context.setFirstName(null);
    context.setMiddleName(null);
    context.setStatusCheckboxpd(false);
    context.setStatusCheckboxnl(false);
    context.setLogged(false);
  };

  const validationSchema = yup.object().shape({
    nameUser: yup
      .string(<FormattedMessage id={"nameUser.required-1"} />)
      .min(2, <FormattedMessage id={"nameUser.required-2"} />)
      .required(<FormattedMessage id={"nameUser.required-3"} />),
    surName: yup
      .string(<FormattedMessage id={"surName.required-1"} />)
      .min(2, <FormattedMessage id={"surName.required-2"} />)
      .required(<FormattedMessage id={"surName.required-3"} />),
    firstName: yup
      .string(<FormattedMessage id={"name.required-1-short"} />)
      .min(2, <FormattedMessage id={"name.required-2-short"} />)
      .required(<FormattedMessage id={"name.required-3-short"} />),
  });

  const formik = useFormik({
    initialValues: {
      // email: context.userMail ? context.userMail : "",
      email: context.userMail ? context.userMailing : "",
      nameUser: context.userName ? context.userName : "",
      surName: context.surName ? context.surName : "",
      firstName: context.firstName ? context.firstName : "",
      middleName: context.middleName ? context.middleName : "",
      statusCheckboxNL: context.statusCheckboxnl,
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      imgFileName =
        slugify(formik.values.firstName).toLocaleLowerCase() +
        "_" +
        slugify(formik.values.surName).toLocaleLowerCase();
      setProgressShow(true);
      if (imageData) {
        uploadFile()
          .then(async () => {
            updateUser().then(() => {
              getRefreshToken();
            });
          })
          .catch((error) => {
            console.log(error);
            setErrorStatus(true);
          });
        setProgressShow(false);
        setImageData(null);
      } else {
        updateUser()
          .then(() => {
            getRefreshToken();
            setSaveStatus(true);
            setProgressShow(false);
            setImageData(null);
          })
          .catch((error) => {
            console.log(error);
            setErrorStatus(true);
          });
      }
    },
  });

  return (
    <section className="user-account mb-5">
      <DownAngleInnerPage
        title={<FormattedMessage id={"user-account-details"} />}
      />
      <Container className="content text-center">
        <Row className="mb-3">
          <Col className="col-3 left d-none d-sm-block d-sm-none d-md-block" />
          <Col>
            <Card>
              <LinearProgress className={progressShow ? "show" : "hide"} />
              <Collapse in={saveStatus}>
                <Alert
                  severity="success"
                  onClick={() => {
                    setSaveStatus(false);
                  }}
                >
                  <AlertTitle>
                    <p className="message">
                      <FormattedMessage
                        id={"save-user-account-success-message"}
                      />
                    </p>
                  </AlertTitle>
                </Alert>
              </Collapse>
              <Collapse in={deleteStatus}>
                <Alert
                  severity="warning"
                  onClick={() => {
                    setDeleteStatus(false);
                  }}
                >
                  <AlertTitle>
                    <p className="message">
                      <FormattedMessage
                        id={"delete-user-account-success-message"}
                      />
                    </p>
                  </AlertTitle>
                </Alert>
              </Collapse>
              <Collapse in={errorStatus}>
                <Alert
                  severity="error"
                  onClick={() => {
                    setErrorStatus(false);
                  }}
                >
                  <AlertTitle>
                    <p className="message">{errorMessage}</p>
                  </AlertTitle>
                </Alert>
              </Collapse>
              <div className="shadow">
                <CardMedia
                  className="mb-3"
                  component="img"
                  alt="Contemplative Reptile"
                  height="140"
                  image={Image}
                  title="Contemplative Reptile"
                />
              </div>
              {imageData || urlUserPicture ? (
                imageData ? (
                  <div className="avatar">
                    <img src={imageData} alt="avatar" />
                  </div>
                ) : (
                  <div className="avatar">
                    <img src={urlUserPicture} alt="avatar" />
                  </div>
                )
              ) : (
                <div className="avatar">
                  <PersonOutlineIcon />
                </div>
              )}
              <Tooltip
                title={
                  context.locale === "ru-RU"
                    ? "Загрузить изображение"
                    : "Upload image"
                }
                TransitionComponent={Zoom}
                arrow
              >
                <label className="photo-icon-wrap" htmlFor="icon-button-file">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onChange={(e) => handleImageChange(e)}
                  >
                    <PhotoCamera className="photo-icon" />
                  </IconButton>
                </label>
              </Tooltip>
              <input
                hidden
                accept="image/*"
                id="icon-button-file"
                type="file"
                onChange={(e) => handleImageChange(e)}
              />
              <form className="mb-2 p-4" onSubmit={formik.handleSubmit}>
                <TextField
                  name="email"
                  type="email"
                  label={<FormattedMessage id={"email.required-1-2"} />}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                <Tooltip
                  title={<FormattedMessage id={"message-tooltip-nameUser"} />}
                  TransitionComponent={Zoom}
                  arrow
                >
                  <TextField
                    name="nameUser"
                    type="text"
                    label={<FormattedMessage id={"nameUser.required-1"} />}
                    value={formik.values.nameUser}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.nameUser && Boolean(formik.errors.nameUser)
                    }
                    helperText={
                      formik.touched.nameUser && formik.errors.nameUser
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Tooltip>
                <br />
                <TextField
                  name="surName"
                  label={<FormattedMessage id={"surName.required-1"} />}
                  value={formik.values.surName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.surName && Boolean(formik.errors.surName)
                  }
                  helperText={formik.touched.surName && formik.errors.surName}
                />
                <TextField
                  name="firstName"
                  label={<FormattedMessage id={"name.required-1-short"} />}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
                <TextField
                  name="middleName"
                  label={<FormattedMessage id={"middleName"} />}
                  value={formik.values.middleName}
                  onChange={formik.handleChange}
                />
                <br />
                <FormGroup className="m-2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="statusCheckboxNL"
                        value={formik.values.statusCheckboxNL}
                        checked={formik.values.statusCheckboxNL}
                        onChange={formik.handleChange}
                        color="primary"
                      />
                    }
                    label={
                      <div className="consent text-start">
                        <span>
                          <FormattedMessage id={"consent-pd-text-6"} />
                        </span>
                      </div>
                    }
                  />
                </FormGroup>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  endIcon={<Icon>save</Icon>}
                >
                  <FormattedMessage id={"save"} />
                </Button>
                <Button
                  className="ms-1 btn-delete"
                  variant="outlined"
                  color="secondary"
                  endIcon={<Icon>delete</Icon>}
                  onClick={() => {
                    setPopupShow(true);
                  }}
                >
                  <FormattedMessage id={"delete"} />
                </Button>
                <Collapse in={popupShow}>
                  <Alert severity="warning">
                    <AlertTitle>
                      <p className="message">
                        <FormattedMessage id={"delete-question"} />
                      </p>
                      <div className="text-center">
                        <Button
                          className="mt-3"
                          variant="contained"
                          color="secondary"
                          endIcon={<Icon>delete</Icon>}
                          onClick={deleteUserAccount}
                        >
                          <FormattedMessage id={"yes"} />
                        </Button>
                        <Button
                          className="mt-3 ms-2"
                          variant="contained"
                          onClick={() => {
                            setPopupShow(false);
                          }}
                        >
                          <FormattedMessage id={"no"} />
                        </Button>
                      </div>
                    </AlertTitle>
                  </Alert>
                </Collapse>
              </form>
            </Card>
          </Col>
          <Col className="col-3 left d-none d-sm-block d-sm-none d-md-block" />
        </Row>
      </Container>
    </section>
  );
};

export default UserAccount;
