import axios from "axios";
import url from "../../environments/environment";

const RecurringPayments = async (value) => {
  const config = {
    method: "GET",
    url: url.cms_headless_url + "/invoice/api?amount=" + value,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.message;
    });
};

export default RecurringPayments;
