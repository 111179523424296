import React, { useLayoutEffect, useRef, useState } from "react";
import { motion, useTransform, useScroll } from "framer-motion";
import ScrollAnimation from "react-animate-on-scroll";
import { FormattedMessage } from "react-intl";

import FundPartners from "../../../../assets/image/fund-partners.png";
import "./Section-slogan.sass";

const SectionSlogan = () => {
  const { scrollY } = useScroll();
  const [elementParallax, setElementParallax] = useState(0);
  const yBackground = useTransform(
    scrollY,
    [elementParallax, elementParallax + 2],
    [0, -1],
    { clamp: false }
  );
  const refSection = useRef(null);

  useLayoutEffect(() => {
    const element = refSection.current;
    setElementParallax(element.offsetTop);
  }, [refSection]);

  return (
    <section id="section-slogan" className="section-slogan" ref={refSection}>
      <div className="section-text-slogan">
        <div className="content cms-content scale-font txt-center">
          <ScrollAnimation animateIn="fadeInLeft">
            <h4>
              <FormattedMessage id={"section-slogan-word1"} />
              <strong>
                <FormattedMessage id={"section-slogan-word2"} />
              </strong>
              <FormattedMessage id={"section-slogan-word3"} />!
            </h4>
          </ScrollAnimation>
        </div>
      </div>
      <motion.div className="fixed-img-container" style={{ y: yBackground }}>
        <div
          className="fixed-img"
          data-bg={FundPartners}
          data-direction="vertical"
          data-depth="0.45"
          style={{
            transform: "translate3d(0px, -553.95px, 0px) rotate(0deg)",
            backgroundImage: `url(${FundPartners})`,
          }}
        ></div>
      </motion.div>
    </section>
  );
};

export default SectionSlogan;
