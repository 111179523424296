import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";

import {
  Button,
  CardActions,
  CardContent,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Input,
  TextField,
  Icon,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MoneyIcon from "@mui/icons-material/Money";

import RecurrentPaymentInfo from "../../../modal/recurrent-payment-info/Recurrent-payment-info";
import RecurringPayments from "../../../../rest/recurring-payments/Recurring-payments";
import "./Donate-block.sass";

const DonateBlock = (props) => {
  const [modalShow, setModalShow] = useState(false);

  const [amount, setAmount] = useState("250");
  const [amountRecurrent, setAmountRecurrent] = useState("250");
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const validationSchema = yup.object().shape({
    statusCheckbox: yup
      .bool()
      .oneOf(
        [true],
        <FormattedMessage id={"consent-recurrent-message-error"} />
      ),
    amount: yup
      .number(<FormattedMessage id={"amount.required-1"} />)
      .required(<FormattedMessage id={"amount.required-3"} />),
  });

  const makeRecurringPayment = () => {
    setModalShow(false);
    setAmountRecurrent(formik.values.amount);
    RecurringPayments(formik.values.amount)
      .then((res) => {
        if (res.Envelope.Body.InvoiceResponse.transaction) {
          let operationId = res.Envelope.Body.InvoiceResponse.transaction;
          console.log(res.Envelope.Body.InvoiceResponse.transaction);
          window.open(
            "https://www.payanyway.ru/assistant.htm?operationId=" + operationId,
            "_blank"
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      statusCheckbox: false,
      amount: amountRecurrent,
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      setModalShow(true);
    },
  });

  return (
    <section className="donate-block">
      <p>
        <small>
          <FormattedMessage id={"donate-sec-desc-text1"} /> PA
          <span className="letter-1">Y</span>AN
          <span className="letter-2">Y</span>WA
          <span className="letter-3">Y.</span>
        </small>
      </p>
      <p>
        <small>
          <FormattedMessage id={"donate-sec-desc-text2"} />
        </small>
      </p>
      <Form
        className="mb-2"
        method="post"
        action="https://www.payanyway.ru/assistant.htm"
        target="_blank"
      >
        <Input type="hidden" name="MNT_ID" value="43364138" />
        <Input type="hidden" name="MNT_TRANSACTION_ID" value="" />
        <Input type="hidden" name="MNT_CURRENCY_CODE" value="RUB" />
        <Input type="hidden" name="MNT_DESCRIPTION" value={props.title} />
        <div className="group-text">
          <div className="amount">
            <Input
              name="MNT_AMOUNT"
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
            />
            <div className="rub">
              <FormattedMessage id={"rub-short"} />
            </div>
          </div>
          <Button
            variant="outlined"
            className="ms-2 py-2 btn-send"
            type="submit"
          >
            <FormattedMessage id={"send"} />
          </Button>
        </div>
      </Form>
      <div className="donate-block__recurrent-block">
        <CardActions disableSpacing>
          <div className="recurrent-block__text" onClick={handleExpandClick}>
            <FormattedMessage id={"recurring-payment"} />
          </div>
          <div className="recurrent-block__icons" onClick={handleExpandClick}>
            <MoneyIcon className="money-icon" />
            <ExpandMore
              className="expanded"
              expand={expanded.toString()}
              aria-expanded={expanded.toString()}
              aria-label="show more"
            >
              <ExpandMore />
            </ExpandMore>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Form onSubmit={formik.handleSubmit}>
              <input type="hidden" name="MNT_ID" value="43364138" />
              <input type="hidden" name="MNT_TRANSACTION_ID" value="" />
              <input type="hidden" name="MNT_CURRENCY_CODE" value="RUB" />
              <input type="hidden" name="MNT_DESCRIPTION" value={""} />
              <FormGroup className="m-2">
                <FormControlLabel
                  className="mt-3"
                  control={
                    <Checkbox
                      name="statusCheckbox"
                      value={formik.values.statusCheckbox}
                      checked={formik.values.statusCheckbox}
                      onChange={formik.handleChange}
                      error={formik.errors.statusCheckbox}
                      color="primary"
                      className="statusCheckbox"
                    />
                  }
                  label={
                    <div className="consent text-start">
                      <span>
                        <FormattedMessage id={"consent-recurrent-text-1"} />
                      </span>
                      <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://cms-headless.fund-dm.ru/sites/default/files/2021-12/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%A4%D0%BE%D0%BD%D0%B4.pdf"
                      >
                        <FormattedMessage id={"consent-recurrent-text-2"} />
                      </a>
                      <span>
                        <FormattedMessage id={"consent-recurrent-text-3"} />
                      </span>
                    </div>
                  }
                />
                <FormHelperText
                  className={
                    !formik.errors.statusCheckbox
                      ? "error-status-text-hide"
                      : "error-status-text-show"
                  }
                >
                  <FormattedMessage id={"consent-recurrent-message-error"} />
                </FormHelperText>
              </FormGroup>
              <FormGroup className="m-2 amount-group">
                <div className="amount-group me-2">
                  <TextField
                    className="amount"
                    name="amount"
                    type="amount"
                    label={<FormattedMessage id={"amount.required-1"} />}
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.amount && Boolean(formik.errors.amount)
                    }
                    helperText={formik.touched.amount && formik.errors.amount}
                  />
                  <span className="rub ms-2">
                    <FormattedMessage id={"rub-short"} />
                  </span>
                </div>
                <Button
                  color="primary"
                  variant="contained"
                  className="py-2"
                  type="submit"
                  endIcon={<Icon>send</Icon>}
                >
                  <FormattedMessage id={"send"} />
                </Button>
              </FormGroup>
            </Form>
          </CardContent>
        </Collapse>
      </div>
      <Button
        style={{ width: "100%" }}
        variant="outlined"
        className="offer-agreement mt-1"
      >
        <a
          href="https://cms-headless.fund-dm.ru/sites/default/files/2021-06/%D0%94%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D1%80%20%D0%BE%D1%84%D0%B5%D1%80%D1%82%D1%8B.pdf"
          download
          target="_blank"
          rel="noreferrer"
          className="offer"
        >
          <GetAppIcon />
          <span style={{ fontWeight: 400 }} className="ml-2">
            <FormattedMessage id={"donate-public-offer-agreement"} />
          </span>
        </a>
      </Button>

      <RecurrentPaymentInfo
        show={modalShow}
        onHide={() => makeRecurringPayment()}
      />
    </section>
  );
};

export default DonateBlock;
