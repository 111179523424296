import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Container, Row } from "react-bootstrap";

import { Zoom, Tooltip, Pagination } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ReplyIcon from "@mui/icons-material/Reply";

import useSWR from "swr";
import { FormattedMessage } from "react-intl";

import { Context } from "../../../../contexts/Context";
import DownAngleInnerPage from "../../../../pages-inner/down-angle-inner-page/Down-angle-inner-page";
import Project from "../../../../items/project/Project";
import environment from "../../../../../environments/environment";
import fetcher from "../../../../../rest/fetcher";
import SceletonProject from "../../../../sceletons/Sceleton-project";
import "./Page-projects.sass";

const variantsSection = {
  hidden: {
    opacity: 0,
    y: "20vh",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
    },
  },
};

const PageProjects = () => {
  const context = useContext(Context);
  let navigate = useNavigate();

  const url =
    environment.cms_headless_url +
    "/api/rest/node?_format=json&field_type_page_target_id=Проекты&langcode=";

  const { data, error } = useSWR(
    context.locale === "en-US" ? url + "en" : url + "ru",
    fetcher
  );

  const [itemsProject, setItemsProject] = useState([]);
  const [currItem, setCurrItem] = useState(1);
  const [itemsPer] = useState(8);

  // Get current items
  const indexOfLast = currItem * itemsPer;
  const indexOfFirst = indexOfLast - itemsPer;
  const currItemsProject = itemsProject.slice(indexOfFirst, indexOfLast);

  // Change page Project
  const handleChangeProject = (event, value) => {
    setCurrItem(value);
  };

  function goBack() {
    navigate(-1);
  }

  let scetetonList = [];
  for (let i = 0; i < 8; i++) {
    scetetonList.push(<SceletonProject key={i} />);
  }

  useEffect(() => {
    document.body.scrollIntoView({ behavior: "auto", block: "start" });
    if (data) {
      setItemsProject(data);
    }
  }, [data]);

  if (error) return console.log(error);
  return (
    <>
      <DownAngleInnerPage title={<FormattedMessage id={"projects"} />} />
      <motion.section
        className="projects"
        variants={variantsSection}
        initial="hidden"
        animate="visible"
      >
        <Container>
          <section className="content text-center mt-0 mb-5">
            {data ? (
              <Row>
                {currItemsProject.map((data) => (
                  <Project key={data.nid} data={data} />
                ))}
                <div style={{ width: "100%" }} />
                {data.length > 8 && (
                  <Pagination
                    className="mt-4"
                    count={Math.ceil(itemsProject.length / 8)}
                    color="primary"
                    onChange={handleChangeProject}
                    showFirstButton
                    showLastButton
                    shape="rounded"
                  />
                )}
              </Row>
            ) : (
              <Row>{scetetonList}</Row>
            )}
          </section>
          <div className="mt-4 mb-4 text-center">
            <Tooltip
              title={context.locale === "ru-RU" ? "На главную" : "To the main"}
              TransitionComponent={Zoom}
              arrow
            >
              <button>
                <Link to={""}>
                  <HomeIcon color="primary" />
                </Link>
              </button>
            </Tooltip>
            <Tooltip
              title={
                context.locale === "ru-RU" ? "На предыдущую" : "To the previous"
              }
              TransitionComponent={Zoom}
              arrow
            >
              <button onClick={() => goBack()}>
                <ReplyIcon color="primary" />
              </button>
            </Tooltip>
          </div>
        </Container>
      </motion.section>
    </>
  );
};

export default PageProjects;
