import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";

import { Tooltip, Zoom } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ReplyIcon from "@mui/icons-material/Reply";

import { Context } from "../../contexts/Context";
import DownAngleInnerPage from "../../pages-inner/down-angle-inner-page/Down-angle-inner-page";
import "./User-agreement.sass";

const variantsSection = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.7,
    },
  },
};

const variantsFooter = {
  hidden: {
    opacity: 0,
    y: "20%",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.5,
      duration: 0.7,
      type: "tween",
    },
  },
};

const UserAgreement = () => {
  const context = useContext(Context);
  let navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  useEffect(() => {
    document.body.scrollIntoView({ behavior: "auto", block: "start" });
  }, []);

  return (
    <>
      <DownAngleInnerPage
        title={
          context.locale === "ru-RU"
            ? "Соглашение пользователя"
            : "User's consent"
        }
      />
      <motion.section
        className="user-agreement"
        variants={variantsSection}
        initial="hidden"
        animate="visible"
      >
        <Container className="mt-4">
          <Row>
            <Col className="col-1 d-none d-sm-block d-sm-none d-md-block" />
            <Col col={10} className="mt-0 mb-5">
              <section className="content mt-0 mb-5 pe-5 ps-5">
                <h1>Согласие на обработку персональных данных Пользователя</h1>
                <p>
                  Я (далее — Пользователь), действуя свободно, своей волей и в
                  своем интересе, даю конкретное, информированное и сознательное
                  согласие Благотворительному Фонду поддержки социальных
                  инициатив «ДРУЗЬЯ МИЛОСЕРДИЯ», место нахождения: 196247,
                  г.&nbsp;Тюмень, пр-д.&nbsp;Воронинские горки, д.&nbsp;182
                  (далее — Оператор) на обработку моих персональных данных, а
                  также подтверждаю факт ознакомления с Политикой обработки и
                  защиты персональных данных, размещенной на официальном сайте
                  Оператора в сети Интернет (
                  {
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://cms-headless.fund-dm.ru/sites/default/files/2021-09/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%B1%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf"
                    >
                      ссылка
                    </a>
                  }
                  ).
                </p>
                <h4>
                  Настоящие Согласие распространяется на следующие персональные
                  данные:
                </h4>
                <ul>
                  <li>Фамилия, имя, отчество;</li>
                  <li>Адрес электронной почты;</li>
                  <li>
                    Фотография (изображение Пользователя, загруженное им в своем
                    личном кабинете на Сайте);
                  </li>
                  <li>
                    Прочие сведения о Пользователе, указанные им самостоятельно
                    (по своему добровольному решению) при обращении им на Сайт в
                    форме обратной связи Сайта или по электронной почте.
                  </li>
                </ul>
                <h4>
                  Согласие на обработку персональных данных дается мной для
                  следующих целей:
                </h4>
                <ul>
                  <li>
                    Оказание Пользователям услуг, обеспечение исполнения
                    договоров с Пользователями (включая договор Публичной оферты
                    поддержки социальных инициатив - Договор пожертвования), в
                    том числе с использованием технологических инструментов;
                  </li>
                  <li>
                    Предоставление Пользователям возможности для обратной связи
                    с Оператором;
                  </li>
                  <li>
                    Коммуникация с Пользователями: направление уведомлений,
                    электронных писем, информации и прочих сообщений;
                  </li>
                  <li>
                    Мониторинг Сайта Оператора: проверка сведений об
                    использовании Сайта и прочих технологических сервисов,
                    оптимизация их функциональных характеристик;
                  </li>
                  <li>
                    Предоставление Пользователям технической поддержки по
                    продуктам и услугам и повышение качества продуктов и услуг
                    Оператора;
                  </li>
                  <li>
                    Обеспечение наиболее эффективного отображения контента Сайта
                    Оператора для Пользователей, а также измерение и изучение
                    эффективности рекламы, которую отображает Оператор для
                    Пользователей Сайта;
                  </li>
                  <li>
                    Направление Пользователю информационных и/или рекламных
                    сообщений (в том числе таргетированных рекламных материалов)
                    об услугах, о событиях в деятельности Оператора, о
                    мероприятиях, организуемых Оператором (в том числе
                    конференциях, вебинарах).
                  </li>
                </ul>
                <p>
                  Оператор может предоставить персональные данные Пользователя
                  по запросу уполномоченных органов государственной власти РФ
                  только по основаниям и в порядке, установленным
                  законодательством РФ.
                </p>
                <p>
                  Датой выдачи Согласия на обработку персональных данных
                  Пользователя является дата проставления галочки при отправке
                  любой веб-формы с Сайта (fund-dm.ru), а также нажатие на
                  кнопку отправки электронного письма, содержащего персональные
                  данные Пользователя, на адреса электронной почты
                  Благотворительного Фонда поддержки социальных инициатив
                  «Друзья Милосердия» (адреса почтового сервера домена
                  @fund-dm.ru).
                </p>
                <p>
                  Настоящее Согласие выдано без ограничения срока его действия,
                  но не дольше, чем это предусмотрено законодательством
                  Российской Федерации или необходимо для достижения целей
                  обработки персональных данных.
                </p>
                <p>
                  Согласие на обработку персональных данных может быть отозвано
                  в любой момент в период его действия путем направления письма,
                  содержащего письменное сообщение с соответствующим обращением
                  (запросом), по почтовому адресу Оператора: 196247,
                  г.&nbsp;Тюмень, пр-д.&nbsp;Воронинские горки, д.&nbsp;182 или
                  на электронный адрес Оператора: email-manager@fund-dm.ru.
                  Запрос также может быть направлен посредством формы обратной
                  связи Сайта (fund-dm.ru).
                </p>
              </section>
              <div className="ms-4 mt-4 text-center">
                <Tooltip
                  title={
                    context.locale === "ru-RU" ? "На главную" : "To the main"
                  }
                  TransitionComponent={Zoom}
                  arrow
                >
                  <button>
                    <Link to={""}>
                      <HomeIcon color="primary" />
                    </Link>
                  </button>
                </Tooltip>
                <Tooltip
                  title={
                    context.locale === "ru-RU"
                      ? "На предыдущую"
                      : "To the previous"
                  }
                  TransitionComponent={Zoom}
                  arrow
                >
                  <button onClick={() => goBack()}>
                    <ReplyIcon color="primary" />
                  </button>
                </Tooltip>
              </div>
            </Col>
            <Col className="col-1 d-none d-sm-block d-sm-none d-md-block" />
          </Row>
        </Container>
        <motion.div variants={variantsFooter}></motion.div>
      </motion.section>
    </>
  );
};

export default UserAgreement;
