import axios from "axios";
import environment from "../../environments/environment";

const restUploadFile = async (data, tokenCSRF, accessToken, imgFileName) => {
  // console.log("data", data);
  // console.log("tokenCSRF", tokenCSRF);
  // console.log("accessToken", accessToken);
  // console.log("imgFileName", imgFileName);
  const config = {
    method: "post",
    url:
      environment.cms_headless_url +
      "/file/upload/media/image/field_media_image?_format=hal_json",
    headers: {
      Authorization: "Bearer " + accessToken,
      "X-CSRF-Token": tokenCSRF,
      "Content-Type": "application/octet-stream",
      // "Content-Disposition": 'file; filename="user_picture.jpg"',
      "Content-Disposition": `file; filename="${imgFileName}.jpg"`,
    },
    data: data,
  };

  return axios(config)
    .then((response) => {
      return JSON.stringify(response.data);
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export default restUploadFile;
