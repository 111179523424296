import React from 'react'
import { FormattedMessage } from 'react-intl'

import './Scrolling-text.sass'

const ScrollingText = () => {
  return (
    <section className="scrolling-text">
      <h2><FormattedMessage id={"scrolling-text"} /><span /><FormattedMessage id={"scrolling-text"} /></h2>
    </section>
  )
}

export default ScrollingText
