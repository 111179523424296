import axios from "axios";
import environment from "../../environments/environment";

const restOauthPasswordGrant = async (data) => {
  const config = {
    method: "post",
    url: environment.cms_headless_url + "/oauth/token",
    data: data,
  };

  return axios(config)
    .then((response) => {
      return JSON.stringify(response.data);
    })
    .catch((error) => {
      //console.log('!!!!!!!!!!!!!!!!!!!!!.catch')
      if (error.response) {
        // client received an error response (5xx, 4xx)
        //console.log('!!!error.response.status:', error.response.status)
        // console.log(error);
        return error.response;
      } else if (error.request) {
        // client never received a response, or request never left
        //console.log('!!!error.request:', error.request)
        // console.log(error);
        return error.request;
      } else {
        // anything else
        //console.log('!!!error:', error.message)
        // console.log(error);
        return error.message;
      }
    });
};

export default restOauthPasswordGrant;
