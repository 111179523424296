import axios from 'axios'
import environment from '../../environments/environment'

const restRefreshToken = async (data) => {
  const config = {
    method: 'post',
    url:  environment.cms_headless_url + '/oauth/token',
    data : data
  }
  return axios(config)
    .then(response => {
      return JSON.stringify(response.data)
    })
    .catch(function (error) {
      console.log(error)
      return error
    })
}


export default restRefreshToken
