import React from "react";
import { Link } from "react-router-dom";
import { Col, Container } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import ScrollAnimation from "react-animate-on-scroll";
import useSWR from "swr";
import { FormattedMessage } from "react-intl";

import fetcher from "../../../../rest/fetcher";
import environment from "../../../../environments/environment";

import "react-multi-carousel/lib/styles.css";
import "./Section-our-team.sass";

const SectionOurTeam = () => {
  const url =
    environment.cms_headless_url + "/api/rest/photo-gallery?_format=json";

  const { error } = useSWR(url, fetcher);

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  if (error) return console.log(error);
  return (
    <section
      id="section-our-team"
      className="section-our-team image-section element-in-view-trigger"
      ref={ref}
    >
      <div className="bg-img" />
      <div className="content-section--wrapper">
        <Container className="content">
          <Col
            fluid="xs"
            className={inView ? "box-lines in-view" : "box-lines"}
          >
            <div className={inView ? "box-top-line in-view" : "box-top-line"} />
            <h3>
              <ScrollAnimation animateIn="fadeInDown" delay={500}>
                <span>
                  <FormattedMessage id={"slogan1"} />
                </span>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn" delay={800}>
                <span>
                  <FormattedMessage id={"slogan2"} />
                </span>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeIn" delay={1100}>
                <span>
                  <FormattedMessage id={"slogan3"} />
                </span>
              </ScrollAnimation>
            </h3>
          </Col>
        </Container>
      </div>
      <div className="down-angle-container">
        <div className="down-angle down-angle-1" />
        <div className="down-angle down-angle-2" />
      </div>
      <div className="button-wrap">
        <p className="txt-center">
          <Link to="become-volunteer" className="button white">
            <FormattedMessage id={"slogan3"} />
          </Link>
        </p>
      </div>
    </section>
  );
};

export default SectionOurTeam;
