import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Form } from "react-bootstrap";
import { FormattedDate, FormattedMessage } from "react-intl";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  Avatar,
  IconButton,
  Typography,
  Button,
  Input,
  Tooltip,
  Zoom,
  LinearProgress,
} from "@mui/material";
import MoneyIcon from "@mui/icons-material/Money";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import { Context } from "../../contexts/Context";
import Logo from "../../../assets/image/logo/logo.svg";
import "../items.sass";

const Project = (props) => {
  const context = useContext(Context);
  const [loading, setLoad] = useState(true);
  const [data, setData] = useState(props.data);
  const [amount, setAmount] = useState("250");
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setData(props.data);
    setLoad(false);
  }, [props.data]);

  return (
    <>
      {!loading && (
        <Col sm={6} lg={4} xl={3} className="section-inner mb-2">
          <Card>
            <CardHeader
              avatar={
                <Avatar
                  style={{ padding: "5px" }}
                  src={Logo}
                  aria-label="recipe"
                  className="avatar"
                />
              }
              className="text-start"
              title={data.title}
              subheader={
                <FormattedDate
                  value={data.created}
                  day="numeric"
                  month="short"
                  year="numeric"
                />
              }
            />
            <CardMedia
              className="media"
              image={data.field_photo_announcement}
            />
            <CardContent>
              <Typography
                className="card-content-text"
                variant="body2"
                color="textSecondary"
                component="p"
              >
                {data.field_abstract_introduction}
              </Typography>
            </CardContent>
            <span style={{ fontSize: "13px" }}>
              <strong>
                <FormattedMessage id={"collected"} />{" "}
                {data.field_collected_amount}{" "}
                <FormattedMessage id={"rub-short"} />
              </strong>
              <FormattedMessage id={"of"} /> {data.field_required_amount}
            </span>
            <LinearProgress
              className="linearProgress"
              variant="determinate"
              value={
                data.field_collected_amount !== "0.00" &&
                data?.field_required_amount !== "0.00"
                  ? parseInt(
                      (parseFloat(data?.field_collected_amount) /
                        parseFloat(data?.field_required_amount)) *
                        100
                    )
                  : 0
              }
            />
            <CardActions className="card-actions" disableSpacing>
              <Link className="group-text" to={"/projects/page/" + data.nid}>
                <ControlPointIcon className="me-2" />
                <span>
                  <FormattedMessage id={"read-more"} />
                </span>
              </Link>
              <Tooltip
                title={context.locale === "ru-RU" ? "Пожертвовать" : "Donate"}
                TransitionComponent={Zoom}
                arrow
              >
                <span onClick={handleExpandClick}>
                  <IconButton aria-label="donate">
                    <MoneyIcon className="orange" />
                  </IconButton>
                  <IconButton
                    aria-label="show more"
                    onClick={handleExpandClick}
                  >
                    <ExpandMoreIcon
                      className="expanded"
                      expand={expanded.toString()}
                      aria-expanded={expanded.toString()}
                      aria-label="show more"
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Form
                  style={{ marginTop: "-10px" }}
                  method="post"
                  action="https://www.payanyway.ru/assistant.htm"
                  target="_blank"
                >
                  <Input type="hidden" name="MNT_ID" value="43364138" />
                  <Input type="hidden" name="MNT_TRANSACTION_ID" value="" />
                  <Input type="hidden" name="MNT_CURRENCY_CODE" value="RUB" />
                  <Input
                    type="hidden"
                    name="MNT_DESCRIPTION"
                    value={data.title}
                  />
                  <div className="group-text flex-column">
                    <span
                      style={{ width: "100% !important" }}
                      className="amount"
                    >
                      <Input
                        name="MNT_AMOUNT"
                        onChange={(e) => setAmount(e.target.value)}
                        value={amount}
                      />
                      <span className="rub">
                        <FormattedMessage id={"rub-short"} />
                      </span>
                    </span>
                    <Button className="button orange" type="submit">
                      <FormattedMessage id={"donate"} />
                    </Button>
                    <Button hidden className="button orange" type="submit">
                      Платежи по подписке
                    </Button>
                    <p>
                      <FormattedMessage id={"donate-sec-desc-text1"} /> PA
                      <span className="letter-1">Y</span>AN
                      <span className="letter-2">Y</span>WA
                      <span className="letter-3">Y.</span>
                    </p>
                    <p className="security-info">
                      <FormattedMessage id={"donate-sec-desc-text2"} />
                    </p>
                  </div>
                </Form>
              </CardContent>
            </Collapse>
          </Card>
        </Col>
      )}
    </>
  );
};

export default Project;
