import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'

import './Cookie-consent.sass'

const variants = {
  hidden: {
    y: '100%'
  },
  visible: {
    y: 0,
    transition: {delay: 4.5, duration: 0.5, type: 'tween' }
  }
}

const CookieConsent = () => {

  const [showSection, setShowSection] = useState(true)

  return (
    <>
      { showSection && (
        <motion.section
          className="cookie-consent"
          variants={variants}
          initial="hidden"
          animate="visible"
        >
          <div className="text-center pt-1">
            <span>
             <FormattedMessage id={"cookie-consent-text-1"} />
              <strong><FormattedMessage id={"cookie-consent-text-2"} /></strong>
              <FormattedMessage id={"cookie-consent-text-3"} /><br/>
              <FormattedMessage id={"cookie-consent-text-4"} />
            </span><br/>
            <span
              className="button mt-2 mb-2"
              onClick={() => {
                setShowSection(false)
              }}
            ><FormattedMessage id={"accepted"} /></span>
            <svg
              xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24" fill="none"
              stroke="currentColor"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
              className="feather feather-plus-circle"
              onClick={() => {
                setShowSection(false)
              }}
            >
              <line x1="12" y1="8" x2="12" y2="16" />
              <line x1="8" y1="12" x2="16" y2="12" />
            </svg>
          </div>

        </motion.section>
      )}
    </>
  )
}

export default CookieConsent
