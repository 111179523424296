import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";
import { motion } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";

import { Tooltip, Zoom } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ReplyIcon from "@mui/icons-material/Reply";

import { Context } from "../../contexts/Context";
import DownAngleInnerPage from "../../pages-inner/down-angle-inner-page/Down-angle-inner-page";
import environment from "../../../environments/environment";
import fetcher from "../../../rest/fetcher";
import SceletonPageInner from "../../sceletons/Sceleton-page-inner";
import DonateSection from "../donate/donate-section/Donate-section";
import "./Page-inner.sass";

const variantsSection = {
  hidden: {
    opacity: 0,
    y: "20vh",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
    },
  },
};

const PageInner = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const context = useContext(Context);
  const url =
    environment.cms_headless_url +
    `/api/rest/node/id?_format=json&nid=${id}&langcode=`;

  const { data, error } = useSWR(
    context.locale === "en-US" ? url + "en" : url + "ru",
    fetcher
  );

  function goBack() {
    navigate(-1);
  }

  useEffect(() => {
    document.body.scrollIntoView({ behavior: "auto", block: "start" });
  }, []);

  if (error) return console.log(error);
  return (
    <section className="section-inner-page">
      {data && <DownAngleInnerPage title={data[0].field_type_page} />}
      <motion.section
        variants={variantsSection}
        initial="hidden"
        animate="visible"
      >
        <Container>
          {data ? (
            <>
              <Row className="mb-3 mt-4">
                <Col className="col-1 bckg-left d-none d-sm-block d-sm-none d-md-block" />
                <Col>
                  <section className="content text-center mt-0 mb-5 pe-5 ps-5">
                    {data && (
                      <motion.div variants={variantsSection}>
                        {parse(data[0].body)}
                      </motion.div>
                    )}
                  </section>
                </Col>
                <Col className="col-1 bckg-right d-none d-sm-block d-sm-none d-md-block" />
              </Row>
              <h1 className="text-center donate">
                {" "}
                <FormattedMessage id={"donate"} />
              </h1>
              {data[0].field_type_page === "Адресная помощь" ||
              data[0].field_type_page === "Проекты" ? (
                <DonateSection title={data[0].title} />
              ) : (
                <DonateSection title="" />
              )}
            </>
          ) : (
            <Row>
              <Col className="col-1 bckg-left d-none d-sm-block d-sm-none d-md-block" />
              <Col>
                <section className="content text-center mt-0 mb-5 pe-5 ps-5">
                  <SceletonPageInner />
                </section>
              </Col>
              <Col className="col-1 bckg-right d-none d-sm-block d-sm-none d-md-block" />
            </Row>
          )}
          <div className="mt-4 mb-5 text-center">
            <Tooltip
              title={context.locale === "ru-RU" ? "На главную" : "To the main"}
              TransitionComponent={Zoom}
              arrow
            >
              <button>
                <Link to={""}>
                  <HomeIcon color="primary" />
                </Link>
              </button>
            </Tooltip>
            <Tooltip
              title={
                context.locale === "ru-RU" ? "На предыдущую" : "To the previous"
              }
              TransitionComponent={Zoom}
              arrow
            >
              <button onClick={() => goBack()}>
                <ReplyIcon color="primary" />
              </button>
            </Tooltip>
          </div>
        </Container>
      </motion.section>
    </section>
  );
};

export default PageInner;
