import React from "react";
import { Col } from "react-bootstrap";
import { Skeleton } from "@mui/material";

const SceletonNews = () => {
  return (
    <>
      <Col lg={3} sm={6} md={6} col={6} xs={6} className={"mb-4"}>
        <Skeleton
          style={{ borderRadius: "10px", paddingTop: "50%" }}
          className="mb-2"
          variant={"rect"}
          animation={"wave"}
        />
        <Skeleton animation={"wave"} height={15} width={"25%"} />
        <Skeleton animation={"wave"} />
        <Skeleton animation={"wave"} width={"50%"} />
      </Col>
    </>
  );
};

export default SceletonNews;
