import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { Context } from "../contexts/Context";

const PrivateRouteHandler = ({ children }) => {
  const context = useContext(Context);
  return context.userAuthenticated === true ? (
    children
  ) : (
    <Navigate to="/" replace />
  );
};

export default PrivateRouteHandler;
