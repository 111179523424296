import React from 'react'
import Briefcase from '../../../../assets/image/svg/briefcase-ico.svg'
import Bullhorn from '../../../../assets/image/svg/bullhorn-ico.svg'
import TargetAssist from '../../../../assets/image/svg/target-assist-ico.svg'
import Handshake from '../../../../assets/image/svg/handshake-ico.svg'
import ScrollAnimation from 'react-animate-on-scroll'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import './Section-activities.sass'

const SectionActivities = () => {
  return (
    <section id="section-activities" className="section-activities">
      <div className="flex-group">
        <div className="flex-inner">
          <Link to={'projects'} className="subgroup">
            <ScrollAnimation className="content" animateIn="fadeInDown" >
              <div className="txt-center">
                <img data-src={Briefcase} alt="Projects" className="svg-icon" width="60" height="50" src={Briefcase} />
              </div>
              <h4 className="text-center">
                <FormattedMessage id={"projects"} />
              </h4>
              <p className="item-text"><FormattedMessage id={"projects-description"} /></p>
            </ScrollAnimation>
            <ScrollAnimation className="link" animateIn="zoomIn"><p className="button color-f"><FormattedMessage id={"read-more"} /></p></ScrollAnimation>
          </Link>
          <Link to={'civil-initiatives'} className="subgroup ">
            <ScrollAnimation className="content" animateIn="fadeInDown" delay={300}>
              <div className="txt-center">
                <img data-src={Bullhorn} alt="Civil initiatives" className="svg-icon" width="60" height="50" src={Bullhorn} />
              </div>
              <h4 className="text-center"><FormattedMessage id={"civil-initiatives"} /></h4>
              <p className="item-text"><FormattedMessage id={"civil-initiatives-description"} /></p>
            </ScrollAnimation>
            <ScrollAnimation className="link" animateIn="zoomIn" delay={300}><p className="button color-d"><FormattedMessage id={"read-more"} /></p></ScrollAnimation>
          </Link>
          <Link to={'targeted-assistance'} className="subgroup ">
            <ScrollAnimation className="content" animateIn="fadeInDown" delay={600}>
              <div className="txt-center">
                <img data-src={TargetAssist} alt="Targeted assistance" className="svg-icon" width="60" height="50" src={TargetAssist} />
              </div>
              <h4 className="text-center"><FormattedMessage id={"targeted-assistance"} /></h4>
              <p className="item-text"><FormattedMessage id={"targeted-assistance-description"} /></p>
            </ScrollAnimation>
            <ScrollAnimation className="link" animateIn="zoomIn" delay={600}><p className="button color-a"><FormattedMessage id={"read-more"} /></p></ScrollAnimation>
          </Link>
          <Link to={'become-volunteer'} className="subgroup">
            <ScrollAnimation className="content" animateIn="fadeInDown" delay={900}>
              <div className="txt-center">
                <img data-src={Handshake} alt="Become a volunteer" className="svg-icon" width="60" height="50" src={Handshake} />
              </div>
              <h4 className="text-center"><FormattedMessage id={"become-volunteer"} /></h4>
              <p className="item-text"><FormattedMessage id={"become-volunteer-description"} /></p>
            </ScrollAnimation>
            <ScrollAnimation className="link" animateIn="zoomIn" delay={900}><p className="button color-c"><FormattedMessage id={"read-more"} /></p></ScrollAnimation>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default SectionActivities
