import React from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import { FormattedDate } from "react-intl";

import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  Typography,
} from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import Logo from "../../../assets/image/logo/logo.svg";
import "../items.sass";

const CivilInitiatives = (props) => {
  const data = props.data;

  return (
    <>
      {data && (
        <Col lg={3} className="section-inner mb-2">
          <Card>
            <CardHeader
              avatar={
                <Avatar
                  style={{ padding: "5px" }}
                  src={Logo}
                  aria-label="recipe"
                  className="avatar"
                />
              }
              className="text-start"
              title={data.title}
              subheader={
                <FormattedDate
                  value={data.created.value}
                  day="numeric"
                  month="short"
                  year="numeric"
                />
              }
            />
            <CardMedia
              className="media"
              image={data.field_photo_announcement}
              title="Paella dish"
            />
            <CardContent>
              <Typography
                className="card-content-text"
                variant="body2"
                color="textSecondary"
                component="p"
              >
                {data.field_abstract_introduction}
              </Typography>
            </CardContent>
            <CardActions className="mb-2" disableSpacing>
              <Link
                className="group-text"
                to={"/civil-initiatives/page/" + data.nid}
              >
                <ControlPointIcon className="me-1" />
                <span>Читать далее</span>
              </Link>
            </CardActions>
          </Card>
        </Col>
      )}
    </>
  );
};

export default CivilInitiatives;
