import ajax from './ajax'
import changePath from './change-path'

const FetcherDocs = async (url) => {
  try {
    const axios = await ajax()
    return axios.get(url).then(res => {
      if (res.data.length !== 0) {
        return changePath(res.data)
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export default FetcherDocs
