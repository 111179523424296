import axios from 'axios'
import url from '../environments/environment'

let singleton = null

const ajax = async () => {
  if (!singleton) {
    const token = url.cms_headless_url + "/session/token"
    try {
      const response = await axios.get(token, {
        method: 'GET'
      })
      const csrfToken = response.data
      singleton = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        }
      })
    } catch (error) {
      console.error(error)
    }
  }
  return singleton
}

export default ajax
