import React from 'react'
import { motion } from 'framer-motion'

import './Down-angle-container.sass'

const variantsDownAngle = {
  visible: {
    borderBottomWidth: '30vw',
    transition: {
      duration: 1.5
    }
  }
}

const DownAngleContainer = () => {
  return (
    <section className="shows-over-fixed">
      <div className="down-angle-container">
        <motion.div
          className="down-angle down-angle-1"
          variants={variantsDownAngle}
          animate="visible"
        />
        <motion.div
          className="down-angle down-angle-2"
          variants={variantsDownAngle}
          animate="visible"
        />
      </div >
    </section>
  )
}

export default DownAngleContainer
