import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import useSWR from "swr";
import Pagination from "@mui/material/Pagination";
import { FormattedMessage } from "react-intl";

import Newspaper from "../../../../assets/image/svg/newspaper-ico.svg";
import { Context } from "../../../contexts/Context";
import environment from "../../../../environments/environment";
import News from "../../../items/news/News";
import fetcher from "../../../../rest/fetcher";
import SceletonNews from "../../../sceletons/Sceleton-news";
import "./Section-news.sass";

const SectionNews = () => {
  const context = useContext(Context);
  const url =
    environment.cms_headless_url +
    "/api/rest/node?_format=json&field_type_page_target_id=Новости&langcode=";

  const { data, error } = useSWR(
    context.locale === "en-US" ? url + "en" : url + "ru",
    fetcher
  );

  const [itemsNews, setItemsNews] = useState([]);
  const [currItem, setCurrItem] = useState(1);
  const [itemsPer] = useState(8);

  // Get current items
  const indexOfLast = currItem * itemsPer;
  const indexOfFirst = indexOfLast - itemsPer;
  const currItemsNews = itemsNews.slice(indexOfFirst, indexOfLast);

  // Change page News
  const handleChangeNews = (event, value) => {
    setCurrItem(value);
  };

  let scetetonList = [];
  for (let i = 0; i < 8; i++) {
    scetetonList.push(<SceletonNews key={i} />);
  }

  useEffect(() => {
    if (data) {
      setItemsNews(data);
    }
  }, [data]);

  if (error) return console.log(error);
  return (
    <section id="section-news" className="section-news">
      <ScrollAnimation animateIn="fadeInDown">
        <h1 className="text-center mt-4 mb-5">
          <img src={Newspaper} alt="News" />
          <FormattedMessage id={"news"} />
        </h1>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp">
        <Container>
          {data ? (
            <Row>
              {currItemsNews.map((data) => (
                <Col key={data.nid} lg={3} sm={6} md={6} col={6} xs={6}>
                  <News data={data} />
                </Col>
              ))}
              <div style={{ width: 306 }} />
              {data.length > 8 && (
                <Pagination
                  className="mt-3"
                  count={Math.ceil(itemsNews.length / 8)}
                  color="primary"
                  onChange={handleChangeNews}
                  showFirstButton
                  showLastButton
                  shape="rounded"
                />
              )}
            </Row>
          ) : (
            <Row>{scetetonList}</Row>
          )}
        </Container>
      </ScrollAnimation>
    </section>
  );
};

export default SectionNews;
