import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";
import { Link, Navigate } from "react-router-dom";
import { slugify } from "transliteration";
import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";
import axios from "axios";

import {
  Card,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  LinearProgress,
  Tooltip,
  IconButton,
  Zoom,
  Alert,
  AlertTitle,
  TextField,
  Button,
  Icon,
} from "@mui/material";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import VisibilityIcon from "@mui/icons-material/Visibility";

import environment from "../../../environments/environment";
import { Context } from "../../contexts/Context";
import DownAngleInnerPage from "../../pages-inner/down-angle-inner-page/Down-angle-inner-page";
import restRegUser from "../../../rest/reg&oauth2/rest-reg-user";
import restOauthPasswordGrant from "../../../rest/reg&oauth2/rest-oauth-password-grant";
import restUploadFile from "../../../rest/reg&oauth2/rest-upload-file";
import restUpdateUser from "../../../rest/reg&oauth2/rest-update-user";
import restRefreshToken from "../../../rest/reg&oauth2/rest-refresh-token";
import "./User-registration.sass";

const UserRegistration = () => {
  const context = useContext(Context);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorStatus, setErrorStatus] = useState(false);
  const [sendStatus, setSendStatus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [fileImage, setFileImage] = useState(null);
  const [tokenCSRF, setTokenCSRF] = useState(null);
  const [progressShow, setProgressShow] = useState(false);
  const currDate = new Date();

  let accessToken;
  let refreshToken;
  let accessTokenExpirationTime;
  let fid;
  let uid;
  let imgFileName = "";
  let encryptedPassword = "";

  useEffect(() => {
    axios.get(environment.cms_headless_url + "/session/token").then((res) => {
      setTokenCSRF(res.data);
    });
  }, []);

  useEffect(() => {
    if (errorStatus) {
      setTimeout(() => {
        setErrorStatus(false);
      }, 8000);
    }
  }, [errorStatus]);

  useEffect(() => {
    if (sendStatus) {
      setTimeout(() => {
        setSendStatus(false);
      }, 1000);
    }
  }, [sendStatus]);

  const handleImageChange = async (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    setFileImage(file);

    if (
      file &&
      file.size < 524288 &&
      (file.type === "image/png" || file.type === "image/jpeg")
    ) {
      reader.onloadend = () => {
        setImageData(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setErrorStatus(true);
      setErrorMessage(
        <FormattedMessage
          id={"error-size-file-avatar"}
          values={{ size: "512", nameType: "jpeg, png" }}
        />
      );
      setImageData(null);
      setFileImage(null);
    }
  };

  const regUser = async () => {
    const privateKey = environment.privateKey;
    const hashDigest = sha256(formik.values.password);
    encryptedPassword = Base64.stringify(hmacSHA512(hashDigest, privateKey));
    const dataUser = {
      _links: {
        type: {
          href: "https://cms-headless.fund-dm.ru/rest/type/user/user",
        },
      },
      mail: [{ value: formik.values.email }],
      name: [{ value: formik.values.nameUser }],
      pass: [{ value: encryptedPassword }],
      field_sur_name: [{ value: formik.values.surName }],
      field_first_name: [{ value: formik.values.firstName }],
      field_middle_name: [{ value: formik.values.middleName }],
      field_statuscheckboxpd: [{ value: formik.values.statusCheckboxPD }],
      field_statuscheckboxnl: [{ value: formik.values.statusCheckboxNL }],
      field_mail_for_distribution: [{ value: formik.values.email }],
    };

    imgFileName =
      slugify(formik.values.firstName).toLocaleLowerCase() +
      "_" +
      slugify(formik.values.surName).toLocaleLowerCase();
    await restRegUser(dataUser, tokenCSRF)
      .then((res) => {
        if (res && res.status === 422) {
          setErrorMessage(
            <FormattedMessage id={"registration-error422-message1"} />
          );
          setProgressShow(false);
          setSendStatus(false);
          setErrorStatus(true);
        } else {
          let resObj = JSON.parse(res);
          uid = resObj["uid"][0].value;
          context.setUID(uid);
          localStorage.setItem("UID", JSON.stringify(uid));
          oauthPasswordGrant().then(async () => {
            if (imageData) {
              uploadFile().then(async () => {
                getRefreshToken().then(async () => {
                  updateUser().then(async () => {
                    context.setUserMail(formik.values.email);
                    context.setUserName(formik.values.nameUser);
                    context.setSurName(formik.values.surName);
                    context.setFirstName(formik.values.firstName);
                    context.setMiddleName(formik.values.middleName);
                    context.setStatusCheckboxpd(formik.values.statusCheckboxPD);
                    context.setStatusCheckboxnl(formik.values.statusCheckboxNL);

                    localStorage.setItem(
                      "userMail",
                      JSON.stringify(formik.values.email)
                    );
                    localStorage.setItem(
                      "userName",
                      JSON.stringify(formik.values.nameUser)
                    );
                    localStorage.setItem(
                      "surName",
                      JSON.stringify(formik.values.surName)
                    );
                    localStorage.setItem(
                      "firstName",
                      JSON.stringify(formik.values.firstName)
                    );
                    localStorage.setItem(
                      "middleName",
                      JSON.stringify(formik.values.middleName)
                    );
                    localStorage.setItem(
                      "statusCheckboxpd",
                      JSON.stringify(formik.values.statusCheckboxPD)
                    );
                    localStorage.setItem(
                      "statusCheckboxnl",
                      JSON.stringify(formik.values.statusCheckboxNL)
                    );

                    getRefreshToken().then(() => {
                      setProgressShow(false);
                      setSendStatus(true);
                      setErrorStatus(false);
                      context.setUserAuthenticated(true);
                      localStorage.setItem(
                        "userAuthenticated",
                        JSON.stringify(true)
                      );
                      setTimeout(() => {
                        <Navigate to="/" replace />;
                      }, 3100);
                    });
                  });
                });
              });
            } else {
              context.setUrlUserPicture(environment.userPictureDefaultUrl);
              context.setUserMail(formik.values.email);
              context.setUserName(formik.values.nameUser);
              context.setSurName(formik.values.surName);
              context.setFirstName(formik.values.firstName);
              context.setMiddleName(formik.values.middleName);
              context.setStatusCheckboxpd(formik.values.statusCheckboxPD);
              context.setStatusCheckboxnl(formik.values.statusCheckboxNL);

              localStorage.setItem(
                "urlUserPicture",
                JSON.stringify(environment.userPictureDefaultUrl)
              );
              localStorage.setItem(
                "userMail",
                JSON.stringify(formik.values.email)
              );
              localStorage.setItem(
                "userName",
                JSON.stringify(formik.values.nameUser)
              );
              localStorage.setItem(
                "surName",
                JSON.stringify(formik.values.surName)
              );
              localStorage.setItem(
                "firstName",
                JSON.stringify(formik.values.firstName)
              );
              localStorage.setItem(
                "middleName",
                JSON.stringify(formik.values.middleName)
              );
              localStorage.setItem(
                "statusCheckboxpd",
                JSON.stringify(formik.values.statusCheckboxPD)
              );
              localStorage.setItem(
                "statusCheckboxnl",
                JSON.stringify(formik.values.statusCheckboxNL)
              );

              setProgressShow(false);
              setSendStatus(true);
              setErrorStatus(false);
              context.setUserAuthenticated(true);
              localStorage.setItem("userAuthenticated", JSON.stringify(true));
              setTimeout(() => {
                <Navigate to="/" replace />;
              }, 1500);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setProgressShow(false);
        setSendStatus(false);
        setErrorStatus(true);
      });
  };

  const oauthPasswordGrant = async () => {
    let FormData = require("form-data");
    let dataCredentials = new FormData();
    dataCredentials.append("grant_type", "password");
    dataCredentials.append("client_id", environment.client_id_webapp);
    dataCredentials.append("client_secret", environment.client_secret_webapp);
    dataCredentials.append("username", formik.values.nameUser);
    dataCredentials.append("password", encryptedPassword);

    await restOauthPasswordGrant(dataCredentials)
      .then((res) => {
        if (res && res.status === 422) {
          setErrorMessage(
            <FormattedMessage id={"registration-error422-message2"} />
          );
          setProgressShow(false);
          setSendStatus(false);
          setErrorStatus(true);
        } else {
          let resObj = JSON.parse(res);
          accessTokenExpirationTime = new Date(
            currDate.setSeconds(currDate.getSeconds() + resObj.expires_in)
          );
          accessToken = resObj["access_token"];
          refreshToken = resObj["refresh_token"];
          context.setAccessToken(accessToken);
          context.setRefreshToken(refreshToken);
          context.setAccessTokenExpirationTime(accessTokenExpirationTime);

          localStorage.setItem("accessToken", JSON.stringify(accessToken));
          localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
          localStorage.setItem(
            "accessTokenExpirationTime",
            JSON.stringify(accessTokenExpirationTime)
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadFile = async () => {
    if (imageData) {
      await restUploadFile(fileImage, tokenCSRF, accessToken, imgFileName)
        .then((res) => {
          let resObj = JSON.parse(res);
          fid = resObj["fid"][0].value;
          context.setFID(fid);
          localStorage.setItem("FID", JSON.stringify(fid));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const updateUser = async () => {
    const data = {
      _links: {
        type: {
          href: "https://cms-headless.fund-dm.ru/rest/type/user/user",
        },
      },
      user_picture: [
        {
          target_id: fid,
          description: "User picture",
        },
      ],
    };
    await restUpdateUser(data, tokenCSRF, accessToken, uid)
      .then((res) => {
        let resObj = JSON.parse(res);
        context.setUserName(resObj["name"][0].value);
        context.setUrlUserPicture(
          resObj["_embedded"][
            "https://cms-headless.fund-dm.ru/rest/relation/user/user/user_picture"
          ][0]["_links"]["self"]["href"]
        );

        localStorage.setItem(
          "userName",
          JSON.stringify(resObj["name"][0].value)
        );
        localStorage.setItem(
          "urlUserPicture",
          JSON.stringify(
            resObj["_embedded"][
              "https://cms-headless.fund-dm.ru/rest/relation/user/user/user_picture"
            ][0]["_links"]["self"]["href"]
          )
        );
      })
      .catch((error) => {
        context.setUserAuthenticated(false);
        localStorage.setItem("userAuthenticated", JSON.stringify(false));
        console.log(error);
      });
  };

  const getRefreshToken = async () => {
    let FormData = require("form-data");
    let data = new FormData();
    data.append("grant_type", "refresh_token");
    data.append("refresh_token", refreshToken);
    data.append("client_id", environment.client_id_webapp);
    data.append("client_secret", environment.client_secret_webapp);

    await restRefreshToken(data).then((res) => {
      let resObj = JSON.parse(res);
      accessTokenExpirationTime = new Date(
        currDate.setSeconds(currDate.getSeconds() + resObj.expires_in)
      );
      accessToken = resObj["access_token"];
      refreshToken = resObj["refresh_token"];
      context.setAccessToken(accessToken);
      context.setRefreshToken(refreshToken);
      context.setAccessTokenExpirationTime(accessTokenExpirationTime);

      localStorage.setItem("accessToken", JSON.stringify(accessToken));
      localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
      localStorage.setItem(
        "accessTokenExpirationTime",
        JSON.stringify(accessTokenExpirationTime)
      );
    });
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string(<FormattedMessage id={"email.required-1"} />)
      .email(<FormattedMessage id={"email.required-2"} />)
      .required(<FormattedMessage id={"email.required-3"} />),
    nameUser: yup
      .string(<FormattedMessage id={"nameUser.required-1"} />)
      .min(2, <FormattedMessage id={"nameUser.required-2"} />)
      .required(<FormattedMessage id={"nameUser.required-3"} />),
    surName: yup
      .string(<FormattedMessage id={"surName.required-1"} />)
      .min(2, <FormattedMessage id={"surName.required-2"} />)
      .required(<FormattedMessage id={"surName.required-3"} />),
    firstName: yup
      .string(<FormattedMessage id={"name.required-1-short"} />)
      .min(2, <FormattedMessage id={"name.required-2-short"} />)
      .required(<FormattedMessage id={"name.required-3-short"} />),
    password: yup
      .string(<FormattedMessage id={"password.required-1"} />)
      .min(8, <FormattedMessage id={"password.required-2"} />)
      .required(<FormattedMessage id={"password.required-3"} />),
    statusCheckboxPD: yup
      .bool()
      .oneOf([true], <FormattedMessage id={"consent-pd-message-error"} />),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      nameUser: "",
      surName: "",
      firstName: "",
      middleName: "",
      login: "",
      password: "",
      statusCheckboxPD: false,
      statusCheckboxNL: false,
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      setProgressShow(true);
      await regUser();
      formik.resetForm();
      setImageData(null);
    },
  });

  return (
    <section className="user-registration mb-5">
      <DownAngleInnerPage
        title={
          context.locale === "ru-RU"
            ? "Регистрация пользователя"
            : "User registration"
        }
      />
      <Container className="content text-center">
        <Row className="mb-3">
          <Col className="col-3 left d-none d-sm-block d-sm-none d-md-block" />
          <Col>
            <Card>
              <LinearProgress className={progressShow ? "show" : "hide"} />
              <Collapse in={sendStatus}>
                <Alert
                  severity="success"
                  onClick={() => {
                    setSendStatus(false);
                  }}
                >
                  <AlertTitle>
                    <p className="message">
                      <FormattedMessage id={"registration-success-message"} />
                    </p>
                  </AlertTitle>
                </Alert>
              </Collapse>
              <Collapse in={errorStatus}>
                <Alert
                  severity="error"
                  onClick={() => {
                    setErrorStatus(false);
                  }}
                >
                  <AlertTitle>
                    <p className="message">{errorMessage}</p>
                  </AlertTitle>
                </Alert>
              </Collapse>
              <div className="shadow" />
              {imageData ? (
                <div className="avatar">
                  <img src={imageData} alt="avatar" />
                </div>
              ) : (
                <div className="avatar">
                  <PersonOutlineIcon />
                </div>
              )}
              <Tooltip
                title={
                  context.locale === "ru-RU"
                    ? "Загрузить изображение"
                    : "Upload image"
                }
                TransitionComponent={Zoom}
                arrow
              >
                <label className="photo-icon-wrap" htmlFor="icon-button-file">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    onChange={(e) => handleImageChange(e)}
                  >
                    <PhotoCamera className="photo-icon" />
                  </IconButton>
                </label>
              </Tooltip>
              <input
                hidden
                accept="image/*"
                id="icon-button-file"
                type="file"
                onChange={(e) => handleImageChange(e)}
              />
              <form className="mb-2 p-4" onSubmit={formik.handleSubmit}>
                <TextField
                  name="email"
                  type="email"
                  label={<FormattedMessage id={"email.required-1-2"} />}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  name="nameUser"
                  type="text"
                  label={<FormattedMessage id={"nameUser.required-1"} />}
                  value={formik.values.nameUser}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.nameUser && Boolean(formik.errors.nameUser)
                  }
                  helperText={formik.touched.nameUser && formik.errors.nameUser}
                />
                <br />
                <TextField
                  name="surName"
                  label={<FormattedMessage id={"surName.required-1"} />}
                  value={formik.values.surName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.surName && Boolean(formik.errors.surName)
                  }
                  helperText={formik.touched.surName && formik.errors.surName}
                />
                <TextField
                  name="firstName"
                  label={<FormattedMessage id={"name.required-1-short"} />}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
                <TextField
                  name="middleName"
                  label={<FormattedMessage id={"middleName"} />}
                  value={formik.values.middleName}
                  onChange={formik.handleChange}
                />
                <br />
                <Tooltip
                  title={
                    context.locale === "ru-RU"
                      ? "Поле Логин заполняется автоматически из значения поля Имя пользователя"
                      : "The Login field is filled in automatically from the value of the Username field"
                  }
                  TransitionComponent={Zoom}
                  arrow
                >
                  <TextField
                    name="login"
                    id="standard-disabled"
                    label={<FormattedMessage id={"login.required-1"} />}
                    value={formik.values.nameUser}
                    onChange={formik.handleChange}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="filled"
                  />
                </Tooltip>
                <TextField
                  name="password"
                  type={showPassword ? "text" : "password"}
                  label={<FormattedMessage id={"password.required-1"} />}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  variant="filled"
                />
                <Tooltip
                  title={
                    showPassword
                      ? context.locale === "ru-RU"
                        ? "Скрыть пароль"
                        : "Hide password"
                      : context.locale === "ru-RU"
                      ? "Показать пароль"
                      : "Show password"
                  }
                  TransitionComponent={Zoom}
                  arrow
                >
                  <VisibilityIcon
                    className={
                      showPassword ? "visible-icon show" : "visible-icon hide"
                    }
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </Tooltip>
                <FormGroup className="m-2">
                  <FormControlLabel
                    className="mt-3"
                    control={
                      <Checkbox
                        name="statusCheckboxPD"
                        value={formik.values.statusCheckboxPD}
                        checked={formik.values.statusCheckboxPD}
                        onChange={formik.handleChange}
                        error={formik.errors.statusCheckboxPD}
                        color="primary"
                      />
                    }
                    label={
                      <div className="consent text-start">
                        <span>
                          <FormattedMessage id={"consent-pd-text-1"} />
                        </span>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://cms-headless.fund-dm.ru/sites/default/files/2021-09/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%B1%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf"
                        >
                          <FormattedMessage id={"consent-pd-text-2"} />
                        </a>
                        <span>
                          {" "}
                          <FormattedMessage id={"consent-pd-text-3"} />{" "}
                        </span>
                        <Link to={"/user-agreement"}>
                          <FormattedMessage id={"consent-pd-text-4"} />
                        </Link>
                        <span>
                          <FormattedMessage id={"consent-pd-text-5"} />
                        </span>
                      </div>
                    }
                  />
                  <FormHelperText
                    className={
                      !formik.errors.statusCheckboxPD
                        ? "hide"
                        : "show statusCheckboxPD"
                    }
                  >
                    <FormattedMessage id={"consent-pd-message-error"} />
                  </FormHelperText>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="statusCheckboxNL"
                        value={formik.values.statusCheckboxNL}
                        checked={formik.values.statusCheckboxNL}
                        onChange={formik.handleChange}
                        color="primary"
                      />
                    }
                    label={
                      <div className="consent text-start">
                        <span>
                          <FormattedMessage id={"consent-pd-text-6"} />
                        </span>
                      </div>
                    }
                  />
                </FormGroup>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  endIcon={<Icon>send</Icon>}
                >
                  <FormattedMessage id={"send"} />
                </Button>
              </form>
            </Card>
          </Col>
          <Col className="col-3 left d-none d-sm-block d-sm-none d-md-block" />
        </Row>
      </Container>
    </section>
  );
};

export default UserRegistration;
