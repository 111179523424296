import { useState, useEffect } from 'react'

const getStorageValue = (key, defaultValue) => {
  // getting stored value
  let initial
  if (typeof window !== "undefined") {
    const saved = localStorage.getItem(key)
    try {
      initial = saved !== null ? JSON.parse(saved) : defaultValue
      //console.log('initial', initial)
    } catch (error) {
      //console.log('Error JSON.parse(saved)', error)
    }
    return initial
  }
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue)
  })

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}
