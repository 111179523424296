import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { FormattedMessage } from "react-intl";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";

import { Tooltip, Zoom } from "@mui/material";

import { Context } from "../../contexts/Context";
import Logo from "../../../assets/image/logo/logo.svg";
import FlagRu from "../../../assets/image/svg/flags/ru.svg";
import FlagEn from "../../../assets/image/svg/flags/gb.svg";
import Login from "../login/Login";
import "./Navbar-header.sass";

const headerVariant = {
  hidden: {
    y: "-120px",
  },
  visible: {
    y: 0,
    transition: {
      delay: 0.9,
      duration: 0,
    },
  },
};

const navVariant = {
  hidden: {
    y: "-120px",
  },
  visible: {
    y: 0,
    transition: {
      delay: 1,
      duration: 0.4,
      type: "spring",
      mass: 0.4,
      damping: 7,
    },
  },
};

const logoVariant = {
  hidden: { rotate: 270 },
  visible: {
    rotate: 0,
    transition: { delay: 1.64, duration: 0.6 },
  },
};

const navLinkBrandVariant = {
  hidden: { marginLeft: "40%" },
  visible: {
    marginLeft: "1%",
    transition: { delay: 1.7, duration: 0.5 },
  },
};

const navSpanBrandVariant = {
  hidden: { display: "none", opacity: 0, marginLeft: -22 },
  visible: {
    display: "unset",
    opacity: 1,
    marginLeft: 0,
    transition: { delay: 2.1, duration: 0.8, ease: "easeInOut" },
  },
};

const NavbarHeader = () => {
  const context = useContext(Context);

  const [flag, setFlag] = useState(() => {
    if (context.locale === "en-US") {
      return FlagEn;
    }
    return FlagRu;
  });

  let location = useLocation();
  let navigate = useNavigate();

  const [hideOnScroll, setHideOnScroll] = useState(true);
  const [styleActive, setStyleActive] = useState("hide-nav");
  const [styleActive2, setStyleActive2] = useState("nav-top hide-nav");
  const [currPosTop, setCurPosTop] = useState(true);
  const [dropdownActions, setDropdownActions] = useState(false);
  const [dropdownDocuments, setDropdownDocuments] = useState(false);
  const [toggleState, setToggleState] = useState(false);

  const refToggle = React.createRef();

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
      if (currPos.y === 0) {
        setStyleActive("hide-nav");
        setStyleActive2("nav-top hide-nav");
        setCurPosTop(true);
      }
      if (hideOnScroll && currPos.y !== 0) {
        setStyleActive("show-nav");
        setStyleActive2("nav-top show-nav");
        setCurPosTop(false);
      }
    },
    [hideOnScroll],
    null,
    false,
    100
  );

  const showDropdownActions = () => {
    setDropdownActions(true);
  };

  const hideDropdownActions = () => {
    setDropdownActions(false);
  };

  const showDropdownDocuments = () => {
    setDropdownDocuments(true);
  };
  const hideDropdownDocuments = () => {
    setDropdownDocuments(false);
  };

  const toggleAction = () => {
    setToggleState(!toggleState);
    if (currPosTop && toggleState) {
      setTimeout(() => {
        setStyleActive("hide-nav");
        setStyleActive2("nav-top hide-nav");
      }, 100);
    } else {
      setStyleActive("show-nav");
      setStyleActive2("nav-top show-nav");
    }
  };

  const anchorGoTop = () => {
    document.body.scrollIntoView({ behavior: "auto", block: "start" });
  };

  const handleClick = (anchor, position) => {
    const pathName = location.pathname;
    const hashName = location.hash;
    if (pathName === "/" || hashName.includes("/#")) {
      let anchorEle = document.getElementById(anchor);
      anchorEle.scrollIntoView({ behavior: "smooth", block: position });
    } else {
      console.log("anchor", anchor);
      navigate(`/#` + anchor, { replace: true });
    }
  };

  return (
    <header className={styleActive}>
      <motion.div
        variants={headerVariant}
        initial="hidden"
        animate="visible"
        className="site-header-inner"
      >
        <Container>
          <nav className={styleActive2}>
            <div className="inner">
              <Link to={"/donate"} className="cta-form-btn">
                <span className="text">
                  <FormattedMessage id={"donate"} />
                </span>
                <FontAwesomeIcon icon={faMoneyBill} />
              </Link>
            </div>
          </nav>
          <motion.nav variants={navVariant} initial="hidden" animate="visible">
            <Navbar expand="lg">
              <Navbar.Brand
                onClick={() => {
                  anchorGoTop();
                }}
              >
                <motion.div variants={navLinkBrandVariant}>
                  <Link to={"/"}>
                    <motion.img
                      className="logo"
                      src={Logo}
                      alt="logo"
                      variants={logoVariant}
                    />
                    <motion.span
                      className="text"
                      variants={navSpanBrandVariant}
                    >
                      <FormattedMessage id={"title-brand"} />
                    </motion.span>
                  </Link>
                </motion.div>
              </Navbar.Brand>
              <Navbar.Toggle
                ref={refToggle}
                onClick={toggleAction}
                className="custom-toggler"
                aria-controls="basic-navbar-nav"
              />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link className="nav-item">
                    <span
                      className="text"
                      onClick={() => {
                        window.innerWidth <= 991 && refToggle.current.click();
                        handleClick("section-about-us", "center");
                      }}
                    >
                      <FormattedMessage id={"about-fund"} />
                      {toggleState}
                    </span>
                  </Nav.Link>
                  <div className="inner-dropdown">
                    <NavDropdown
                      title={
                        <span
                          className="text"
                          onClick={() => {
                            handleClick("section-activities", "center");
                          }}
                        >
                          <FormattedMessage id={"activities"} />
                        </span>
                      }
                      id="actions-nav-dropdown"
                      show={dropdownActions}
                      onMouseEnter={showDropdownActions}
                      onMouseLeave={hideDropdownActions}
                    >
                      <NavDropdown.Item
                        as={Link}
                        to={"/projects"}
                        onClick={() => {
                          window.innerWidth <= 991 && refToggle.current.click();
                        }}
                      >
                        <FormattedMessage id={"projects"} />
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to={"/civil-initiatives"}
                        onClick={() => {
                          window.innerWidth <= 991 && refToggle.current.click();
                        }}
                      >
                        <FormattedMessage id={"civil-initiatives"} />
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to={"/targeted-assistance"}
                        onClick={() => {
                          window.innerWidth <= 991 && refToggle.current.click();
                        }}
                      >
                        <FormattedMessage id={"targeted-assistance"} />
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to={"/become-volunteer"}
                        onClick={() => {
                          window.innerWidth <= 991 && refToggle.current.click();
                        }}
                      >
                        <FormattedMessage id={"become-volunteer"} />
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                  <Nav.Link className="nav-item">
                    <span
                      className="text"
                      onClick={() => {
                        window.innerWidth <= 991 && refToggle.current.click();
                        handleClick("section-news", "end");
                      }}
                    >
                      <FormattedMessage id={"news"} />
                    </span>
                  </Nav.Link>
                  <div className="inner-dropdown">
                    <NavDropdown
                      disabled
                      title={
                        <span className="text">
                          <FormattedMessage id={"documents"} />
                        </span>
                      }
                      id="documents-nav-dropdown"
                      show={dropdownDocuments}
                      onMouseEnter={showDropdownDocuments}
                      onMouseLeave={hideDropdownDocuments}
                    >
                      <NavDropdown.Item
                        as={Link}
                        to={"/reports"}
                        onClick={() => {
                          window.innerWidth <= 991 && refToggle.current.click();
                        }}
                      >
                        <FormattedMessage id={"reports"} />
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to={"/constituent-documents"}
                        onClick={() => {
                          window.innerWidth <= 991 && refToggle.current.click();
                        }}
                      >
                        <FormattedMessage id={"constituent"} />
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to={"/agreements"}
                        onClick={() => {
                          window.innerWidth <= 991 && refToggle.current.click();
                        }}
                      >
                        <FormattedMessage id={"agreements"} />
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to={"/conventions"}
                        onClick={() => {
                          window.innerWidth <= 991 && refToggle.current.click();
                        }}
                      >
                        <FormattedMessage id={"conventions"} />
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to={"/policies"}
                        onClick={() => {
                          window.innerWidth <= 991 && refToggle.current.click();
                        }}
                      >
                        <FormattedMessage id={"policies"} />
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to={"/printing-materials"}
                        onClick={() => {
                          window.innerWidth <= 991 && refToggle.current.click();
                        }}
                      >
                        <FormattedMessage id={"printing-materials"} />
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                  <Nav.Link className="nav-item">
                    <span
                      className="text"
                      onClick={() => {
                        window.innerWidth <= 991 && refToggle.current.click();
                        handleClick("section-contacts", "center");
                      }}
                    >
                      <FormattedMessage id={"contacts"} />
                    </span>
                  </Nav.Link>
                  <Login />
                  <div
                    className="flag"
                    onClick={() => {
                      context.selectLang();
                      context.locale === "en-US"
                        ? setFlag(FlagRu)
                        : setFlag(FlagEn);
                    }}
                  >
                    <Tooltip
                      title={
                        context.locale === "en-US"
                          ? "Change the language"
                          : "Изменить язык"
                      }
                      TransitionComponent={Zoom}
                      arrow
                    >
                      <img width="32px" alt="flag" src={flag} />
                    </Tooltip>
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </motion.nav>
        </Container>
      </motion.div>
    </header>
  );
};

export default NavbarHeader;
