import axios from "axios";
import url from "../../environments/environment";

const restRegUser = async (dataUser, tokenCSRF) => {
  const config = {
    method: "POST",
    url: url.cms_headless_url + "/user/register?_format=hal_json",
    headers: {
      "Content-Type": "application/hal+json",
      "X-CSRF-Token": tokenCSRF,
    },
    data: dataUser,
  };
  return axios(config)
    .then((response) => {
      //console.log('!!!!!!!!!!!!!!!!!!!!!.then')
      //console.log('response:',response)
      //console.log('response.status:',response.status)
      return JSON.stringify(response.data);
    })
    .catch((error) => {
      //console.log('!!!!!!!!!!!!!!!!!!!!!.catch')
      if (error.response) {
        // client received an error response (5xx, 4xx)
        //console.log('!!!error.response.status:', error.response.status)
        console.log(error);
        return error.response;
      } else if (error.request) {
        // client never received a response, or request never left
        //console.log('!!!error.request:', error.request)
        console.log(error);
        return error.request;
      } else {
        // anything else
        //console.log('!!!error:', error.message)
        console.log(error);
        return error.message;
      }
    });
};

export default restRegUser;
