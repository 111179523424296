import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "@mui/material";

import Picture from "../../../assets/image/pic.png";
import "./Recurrent-payment-info.sass";

const RecurrentPaymentInfo = (props) => {
  return (
    <Modal
      {...props}
      className="backdrop"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="modal-recurrent-info">
        <div className="wrap-block">
          <h1 className="title">Спасибо Вам за помощь!</h1>
          <p className="text">
            Сейчас для совершения платежа Вы будете перенаправлены на сервис
            авторизованной платежной системы - PA
            <span className="letter-1">Y</span>AN
            <span className="letter-2">Y</span>WA
            <span className="letter-3">Y</span>
          </p>

          <p className="text">
            После перехода на сервис, для проведения периодических платежей,
            пожалуйста, не забудьте проставить галочку
          </p>

          <img className="picture" src={Picture} alt="pic" />
          <p className="text-small red mb-2">
            Безопасность платежей с помощью банковских карт обеспечивается
            технологиями защищенного соединения HTTPS и двухфакторной
            аутентификацией пользователя 3D Secure
          </p>
          <p className="text-small">
            Сервис PayAnyWay предоставляется НКО "МОНЕТА" (ООО) (лицензия ЦБ РФ
            № 3508-К от 29 ноября 2017 года)
          </p>
          <Button
            color="primary"
            variant="contained"
            className="mt-4 ms-2 py-2"
            type="submit"
            onClick={props.onHide}
          >
            Перейти
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RecurrentPaymentInfo;
