import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";

import { Zoom, Button, Tooltip, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import HomeIcon from "@mui/icons-material/Home";
import ReplyIcon from "@mui/icons-material/Reply";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import DownAngleInnerPage from "../../../pages-inner/down-angle-inner-page/Down-angle-inner-page";
import { Context } from "../../../contexts/Context";
import environment from "../../../../environments/environment";
import FetcherDocs from "../../../../rest/fetcher-docs";
import "../documents.sass";

const variantsSection = {
  hidden: {
    opacity: 0,
    y: "20vh",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
    },
  },
};

const ConstituentDocuments = () => {
  const context = useContext(Context);
  let navigate = useNavigate();

  const url =
    environment.cms_headless_url +
    "/api/rest/docs?_format=json&field_type_doc_target_id=Учредительные документы&langcode=";

  const { data, error } = useSWR(
    context.locale === "en-US" ? url + "en" : url + "ru",
    FetcherDocs
  );

  const [documents, setDocuments] = useState([]);

  const columns = [
    {
      field: "field_document",
      headerName: <FormattedMessage id={"file"} />,
      flex: 0.3,
    },
    {
      field: "title",
      headerName: <FormattedMessage id={"doc-name"} />,
      flex: 1,
    },
    {
      field: "",
      resizable: false,
      renderCell: (params) => (
        <Tooltip
          title={
            context.locale === "ru-RU" ? "Открыть документ" : "Open document"
          }
          TransitionComponent={Zoom}
          arrow
        >
          <a
            className="mx-auto"
            href={params.row.field_document_1}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              className="text-center"
              variant="contained"
              color="secondary"
              startIcon={<AttachFileIcon />}
            />
          </a>
        </Tooltip>
      ),
      renderHeader: () => (
        <CloudDownloadIcon
          style={{ fontSize: "2rem", color: "var(--blue2)" }}
        />
      ),
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  function goBack() {
    navigate(-1);
  }

  useEffect(() => {
    if (data) {
      setDocuments(data);
    }
  }, [data]);

  useEffect(() => {
    document.body.scrollIntoView({ behavior: "auto", block: "start" });
  }, []);

  if (error) return console.log(error);
  return (
    <>
      <DownAngleInnerPage title={<FormattedMessage id={"constituent"} />} />
      <motion.section
        className="documents"
        variants={variantsSection}
        initial="hidden"
        animate="visible"
      >
        <Container>
          <Row>
            <Col className="text-center mt-0 mb-5">
              <section className="content">
                <div style={{ display: "flex", height: "100%" }}>
                  <div style={{ flexGrow: 1 }}>
                    {documents && (
                      <Box sx={{ height: "50vh", width: "100%" }}>
                        <DataGrid
                          columns={columns}
                          rows={documents.map((res) => {
                            return res;
                          })}
                          pageSize={4}
                          showCellRightBorder={true}
                          showColumnRightBorder={true}
                        />
                      </Box>
                    )}
                  </div>
                </div>
              </section>
              <div className="ms-4 mt-4">
                <Tooltip
                  title={
                    context.locale === "ru-RU" ? "На главную" : "To the main"
                  }
                  TransitionComponent={Zoom}
                  arrow
                >
                  <button>
                    <Link to={"/"}>
                      <HomeIcon color="primary" />
                    </Link>
                  </button>
                </Tooltip>
                <Tooltip
                  title={
                    context.locale === "ru-RU"
                      ? "На предыдущую"
                      : "To the previous"
                  }
                  TransitionComponent={Zoom}
                  arrow
                >
                  <button onClick={() => goBack()}>
                    <ReplyIcon color="primary" />
                  </button>
                </Tooltip>
              </div>
            </Col>
          </Row>
        </Container>
      </motion.section>
    </>
  );
};

export default ConstituentDocuments;
