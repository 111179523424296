import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import { Tooltip, Zoom } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ReplyIcon from "@mui/icons-material/Reply";

import DownAngleInnerPage from "../../pages-inner/down-angle-inner-page/Down-angle-inner-page";
import { Context } from "../../contexts/Context";
import DonateSection from "./donate-section/Donate-section";
import "./Donate.sass";

const variantsSection = {
  hidden: {
    opacity: 0,
    y: "20vh",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
    },
  },
};

const Donate = () => {
  const context = useContext(Context);
  let navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  useEffect(() => {
    document.body.scrollIntoView({ behavior: "auto", block: "start" });
  }, []);

  return (
    <>
      <DownAngleInnerPage title={<FormattedMessage id={"financial-help"} />} />
      <motion.section
        className="donate"
        variants={variantsSection}
        initial="hidden"
        animate="visible"
      >
        <Container className="text-center mb-5">
          <section className="content">
            <DonateSection />
          </section>
          <div className="mt-5">
            <Tooltip
              title={context.locale === "ru-RU" ? "На главную" : "To the main"}
              TransitionComponent={Zoom}
              arrow
            >
              <button>
                <Link to={""}>
                  <HomeIcon color="primary" />
                </Link>
              </button>
            </Tooltip>
            <Tooltip
              title={
                context.locale === "ru-RU" ? "На предыдущую" : "To the previous"
              }
              TransitionComponent={Zoom}
              arrow
            >
              <button onClick={() => goBack()}>
                <ReplyIcon color="primary" />
              </button>
            </Tooltip>
          </div>
        </Container>
      </motion.section>
    </>
  );
};

export default Donate;
