import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import ContextProvider from "./components/contexts/Context";
import NavbarHeader from "./components/header/navbar/Navbar-header";
import MainSection from "./components/main/main-section/Main-section";
import PageNotFound from "./components/pages-inner/page-not-found/Page-not-found";
import PageProjects from "./components/main/main-section/section-activities/page-projects/Page-projects";
import PageInner from "./components/pages-inner/page-inner/Page-inner";
import PageCivilInitiatives from "./components/main/main-section/section-activities/page-civil-initiatives/Page-civil-initiatives";
import PageTargetedAssistance from "./components/main/main-section/section-activities/page-targeted-assistance/Page-targeted-assistance";
import PageBecomeVolunteer from "./components/main/main-section/section-activities/page-become-volunteer/Page-become-volunteer";
import Donate from "./components/pages-inner/donate/Donate";
import Reports from "./components/pages-inner/documents/reports/Reports";
import ConstituentDocuments from "./components/pages-inner/documents/constituent-documents/Constituent-documents";
import PrintingMaterials from "./components/pages-inner/documents/printing-materials/Printing-materials";
import PageAboutUs from "./components/main/main-section/section-about-us/page-about-us/Page-about-us";
import Agreements from "./components/pages-inner/documents/agreements/Agreements";
import Policies from "./components/pages-inner/documents/policies/Policies";
import Conventions from "./components/pages-inner/documents/conventions/Conventions";
import CookieConsent from "./components/cookie-consent/Cookie-consent";
import UserRegistration from "./components/user/user-registration/User-registration";
import PrivateRouteHandler from "./components/private-route-handler/private-route-handler";
import UserAccount from "./components/user/user-account/User-account";
import UserAgreement from "./components/user/user-agreement/User-agreement";
import WebStub from "./components/web-stub/WebStub";
import Footer from "./components/footer/Footer";
import { gapi } from "gapi-script";

const clientId =
  "231900329210-1gt09mtmt9dghu51p8mga3s57bi6obtm.apps.googleusercontent.com";

function App() {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "profile",
      });
    }
    gapi.load("client:auth2", start);
  });

  return (
    <BrowserRouter>
      <ContextProvider>
        <NavbarHeader />
        <Routes>
          <Route path={"/"} exact element={<MainSection />} />
          <Route
            path={"/user-registration"}
            exact
            element={<UserRegistration />}
          />
          <Route path={"/donate"} exact element={<Donate />} />
          <Route path={"/projects"} exact element={<PageProjects />} />
          <Route
            path={"/civil-initiatives"}
            exact
            element={<PageCivilInitiatives />}
          />
          <Route
            path={"/targeted-assistance"}
            exact
            element={<PageTargetedAssistance />}
          />
          <Route
            path={"/become-volunteer"}
            exact
            element={<PageBecomeVolunteer />}
          />
          <Route path={"/reports"} exact element={<Reports />} />
          <Route
            path={"/constituent-documents"}
            exact
            element={<ConstituentDocuments />}
          />
          <Route
            path={"/printing-materials"}
            exact
            element={<PrintingMaterials />}
          />
          <Route path={"/agreements"} exact element={<Agreements />} />
          <Route path={"/conventions"} exact element={<Conventions />} />
          <Route path={"/policies"} exact element={<Policies />} />
          <Route path={"/about-us"} exact element={<PageAboutUs />} />
          <Route path={"/news/page/:id"} element={<PageInner />} />
          <Route path={"/projects/page/:id"} element={<PageInner />} />
          <Route path={"/civil-initiatives/page/:id"} element={<PageInner />} />
          <Route
            path={"/targeted-assistance/page/:id"}
            element={<PageInner />}
          />
          <Route path={"/become-volunteer/page/:id"} element={<PageInner />} />
          <Route path={"/user-agreement"} element={<UserAgreement />} />
          <Route
            path="/user-account/:id"
            element={
              <PrivateRouteHandler>
                <UserAccount />
              </PrivateRouteHandler>
            }
          />
          <Route path={"/oauth-callback"} element={<WebStub />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <CookieConsent />
        <Footer />
      </ContextProvider>
    </BrowserRouter>
  );
}

export default App;
