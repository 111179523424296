let environment = {
  emailUrl: "backend/api/mail.php",
  cms_headless_url: "https://cms-headless.fund-dm.ru",
  oauth_callback_url: "https://fund-dm.ru",
  userPictureDefaultUrl:
    "https://cms-headless.fund-dm.ru/sites/default/files/pictures/user-picture-default.jpg",
  client_id_webapp: "c146ecd2-fafe-4291-90aa-ed2a6851d7b5",
  client_secret_webapp: "UserwebappFund2022!",
  client_id_vk: "8216742",
  client_id_ok: "512001485394",
  app_key_ok: "CDFLKLKGDIHBABABA",
  privateKey: "cjajrk",
};

if (process.env.REACT_APP_ENV === "development") {
  environment.emailUrl = "http://localhost:8080/api/mail.php";
  environment.cms_headless_url = "http://localhost:3000";
  environment.oauth_callback_url = "http://localhost:3000";
  environment.userPictureDefaultUrl =
    "https://cms-headless.fund-dm.ru/sites/default/files/pictures/user-picture-default.jpg";
}

if (process.env.REACT_APP_ENV === "production") {
  environment.emailUrl = "backend/api/mail.php";
  environment.cms_headless_url = "https://cms-headless.fund-dm.ru";
  environment.oauth_callback_url = "https://fund-dm.ru";
  environment.userPictureDefaultUrl =
    "https://cms-headless.fund-dm.ru/sites/default/files/pictures/user-picture-default.jpg";
}

export default environment;
