import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import { Tooltip, Zoom } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ReplyIcon from "@mui/icons-material/Reply";
import useSWR from "swr";
import parse from "html-react-parser";

import DownAngleInnerPage from "../../../../pages-inner/down-angle-inner-page/Down-angle-inner-page";

import { Context } from "../../../../contexts/Context";
import environment from "../../../../../environments/environment";
import fetcher from "../../../../../rest/fetcher";
import SceletonPageInner from "../../../../sceletons/Sceleton-page-inner";
import "./Page-about-us.sass";

const variantsSection = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.7,
    },
  },
};

const variantsFooter = {
  hidden: {
    opacity: 0,
    y: "20%",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.5,
      duration: 0.7,
      type: "tween",
    },
  },
};

const PageAboutUs = () => {
  const context = useContext(Context);
  let navigate = useNavigate();

  const url =
    environment.cms_headless_url +
    `/api/rest/node/id?_format=json&field_type_page_target_id=О нас&langcode=`;

  const { data, error } = useSWR(
    context.locale === "en-US" ? url + "en" : url + "ru",
    fetcher
  );

  function goBack() {
    navigate(-1);
  }

  useEffect(() => {
    document.body.scrollIntoView({ behavior: "auto", block: "start" });
  }, []);

  if (error) return console.log(error);
  return (
    <>
      <DownAngleInnerPage
        title={context.locale === "ru-RU" ? "О нас" : "About Us"}
      />
      <motion.section
        className="about-us"
        variants={variantsSection}
        initial="hidden"
        animate="visible"
      >
        <Container className="mt-4">
          {data ? (
            <Row>
              <Col className="col-1 d-none d-sm-block d-sm-none d-md-block" />
              <Col col={10} className="mt-0 mb-5">
                <section className="content mt-0 mb-5 pe-5 ps-5">
                  {parse(data[0].body)}
                </section>
                <div className="ms-4 mt-4 text-center">
                  <Tooltip
                    title={
                      context.locale === "ru-RU" ? "На главную" : "To the main"
                    }
                    TransitionComponent={Zoom}
                    arrow
                  >
                    <button>
                      <Link to={""}>
                        <HomeIcon color="primary" />
                      </Link>
                    </button>
                  </Tooltip>
                  <Tooltip
                    title={
                      context.locale === "ru-RU"
                        ? "На предыдущую"
                        : "To the previous"
                    }
                    TransitionComponent={Zoom}
                    arrow
                  >
                    <button onClick={() => goBack()}>
                      <ReplyIcon color="primary" />
                    </button>
                  </Tooltip>
                </div>
              </Col>
              <Col className="col-1 d-none d-sm-block d-sm-none d-md-block" />
            </Row>
          ) : (
            <Row>
              <Col className="col-1 d-none d-sm-block d-sm-none d-md-block" />
              <Col>
                <section className="content text-center mt-0 mb-5 pe-5 ps-5">
                  <SceletonPageInner />
                </section>
              </Col>
              <Col className="col-1 d-none d-sm-block d-sm-none d-md-block" />
            </Row>
          )}
        </Container>
        <motion.div variants={variantsFooter}></motion.div>
      </motion.section>
    </>
  );
};

export default PageAboutUs;
