import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";
import { slugify } from "transliteration";
import fetchJsonp from "fetch-jsonp";
import axios from "axios";
import SocialLogin from "react-simple-oauth2-login";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { FormattedMessage } from "react-intl";

import {
  Collapse,
  LinearProgress,
  Tooltip,
  Zoom,
  TextField,
  Button,
  Icon,
  Alert,
  AlertTitle,
} from "@mui/material";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SecurityIcon from "@mui/icons-material/Security";
import PublicIcon from "@mui/icons-material/Public";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { Context } from "../../contexts/Context";
import environment from "../../../environments/environment";
import restOauthPasswordGrant from "../../../rest/reg&oauth2/rest-oauth-password-grant";
import restUserDebug from "../../../rest/reg&oauth2/rest-user-debug";
import restGetUserInfo from "../../../rest/reg&oauth2/rest-get-user-info";
import restRegUser from "../../../rest/reg&oauth2/rest-reg-user";
import restUploadFile from "../../../rest/reg&oauth2/rest-upload-file";
import restUpdateUser from "../../../rest/reg&oauth2/rest-update-user";
import restRefreshToken from "../../../rest/reg&oauth2/rest-refresh-token";
import Google from "../../../assets/image/svg/social/google-logo.svg";
import Ok from "../../../assets/image/svg/social/ok.svg";
import Vk from "../../../assets/image/svg/social/vk.svg";
import "./Login.sass";

const Login = () => {
  const context = useContext(Context);
  const clientGoogleId =
    "231900329210-1gt09mtmt9dghu51p8mga3s57bi6obtm.apps.googleusercontent.com";
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorStatus, setErrorStatus] = useState(false);
  const [sendStatus, setSendStatus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [tokenCSRF, setTokenCSRF] = useState(null);
  const [progressShow, setProgressShow] = useState(false);

  const [logged, setLogged] = useState(false);
  const [currentProvider, setCurrentProvider] = useState("");

  const REDIRECT_URI = environment.oauth_callback_url + "/oauth-callback";
  const currDate = new Date();

  let currImageData;
  let currImageFile;
  let accessToken;
  let refreshToken;
  let accessTokenExpirationTime;
  let userIdSocial;
  let uid;
  let fid;
  let urlUserPicture;
  let userMail;
  let userMailing;
  let userName;
  let surName;
  let firstName;
  let middleName;
  let statusCheckboxpd;
  let statusCheckboxnl;
  let socialOAUF = false;
  let imgFileName = "";
  let user = {};
  let md5 = require("md5");
  let encryptedPassword = "";
  let encryptedPasswordNoSocial = "";

  useEffect(() => {
    if (context.userAuthenticated) {
      if (currDate >= new Date(context.accessTokenExpirationTime)) {
        logout();
      }
    }
    axios.get(environment.cms_headless_url + "/session/token").then((res) => {
      setTokenCSRF(res.data);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (errorStatus) {
      setTimeout(() => {
        setErrorStatus(false);
      }, 5000);
    }
  }, [errorStatus]);

  const showMessage = () => {
    setTimeout(() => {
      setSendStatus(false);
      context.setUserAuthenticated(true);
      localStorage.setItem("userAuthenticated", JSON.stringify(true));
    }, 2000);
  };

  const getUserInfoSocial = async (provider, parametrs, sig) => {
    switch (provider) {
      case "vk": {
        try {
          const response = await fetchJsonp(
            `https://api.vk.com/method/users.get?user_id=${parametrs.user_id}&v=5.131&fields=photo_max_orig&access_token=${parametrs.access_token}`
          );
          const json = await response.json();
          return await json.response[0];
        } catch (ex) {
          console.log("parsing failed", ex);
        }
        break;
      }
      case "ok": {
        try {
          const response = await fetch(
            `https://api.ok.ru/fb.do?application_key=${environment.app_key_ok}&format=json&method=users.getCurrentUser&sig=${sig}&access_token=${parametrs.access_token}`
          );
          const json = await response.json();
          return await json;
        } catch (ex) {
          console.log("parsing failed", ex);
        }
        break;
      }
      default:
        break;
    }
  };

  const loginSocialWebApp = async (provider, user) => {
    setLogged(true);
    socialOAUF = true;
    context.setLogged(true);
    localStorage.setItem("logged", JSON.stringify(true));
    setProgressShow(true);

    statusCheckboxpd = true;
    statusCheckboxnl = true;

    switch (provider) {
      case "vk": {
        await oauthPasswordGrant().then(async (res) => {
          if (res?.status === 401) {
            regUserSocial(provider);
          } else {
            await getUserDebug();
            await getUserInfo();
            setProgressShow(false);
            setSendStatus(true);
            setErrorStatus(false);
            showMessage();
            socialOAUF = false;
          }
        });
        break;
      }
      case "ok": {
        await oauthPasswordGrant().then(async (res) => {
          if (res?.status === 401) {
            regUserSocial(provider);
          } else {
            await getUserDebug();
            await getUserInfo();
            setProgressShow(false);
            setSendStatus(true);
            setErrorStatus(false);
            showMessage();
            socialOAUF = false;
          }
        });

        break;
      }
      case "google": {
        await oauthPasswordGrant().then(async (res) => {
          if (res?.status === 401) {
            regUserSocial(provider);
          } else {
            await getUserDebug();
            await getUserInfo();
            setProgressShow(false);
            setSendStatus(true);
            setErrorStatus(false);
            showMessage();
            socialOAUF = false;
          }
        });

        break;
      }
      default:
        break;
    }
  };

  const onSuccessVK = async (response) => {
    await getUserInfoSocial("vk", response).then((data) => {
      user = data;
    });

    if (user) {
      userIdSocial = user.id;
      userName = `${user.first_name} ${
        user.last_name ? user.last_name : ""
      }`.trim();

      firstName = user.first_name;
      surName = user.last_name;
      imgFileName = slugify(user.first_name) + "_" + slugify(user.last_name);

      urlUserPicture = user.photo_max_orig
        ? user.photo_max_orig
        : environment.userPictureDefaultUrl;
      userMail = userIdSocial + "@fund-dm.ru";

      context.setUser({
        provider: "vk",
        accessToken: response.access_token,
        id: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        nickName: user.nickname,
        photo: user.photo_max_orig,
        status: "connected",
      });

      setCurrentProvider("vk");
      context.setCurrentProvider("vk");
      localStorage.setItem("currentProvider", JSON.stringify("vk"));

      await loginSocialWebApp("vk", user);
    }

    setProgressShow(false);
  };

  const onFailureVK = (response) => {
    setProgressShow(false);
    console.error("Error:", response);
  };

  const onSuccessOK = async (response) => {
    const sig = md5(
      `application_key=${environment.app_key_ok}format=jsonmethod=users.getCurrentUser${response.session_secret_key}`
    );
    await getUserInfoSocial("ok", response, sig).then((data) => {
      user = data;
    });

    if (user) {
      userIdSocial = user.uid;
      userName = `${user.first_name} ${
        user.last_name ? user.last_name : ""
      }`.trim();
      firstName = user.first_name;
      surName = user.last_name;
      imgFileName = slugify(user.first_name) + "_" + slugify(user.last_name);

      urlUserPicture = user.pic_3
        ? user.pic_3
        : environment.userPictureDefaultUrl;
      userMail = userIdSocial + "@fund-dm.ru";

      context.setUser({
        provider: "ok",
        accessToken: response.access_token,
        id: user.uid,
        firstName: user.first_name,
        lastNAme: user.last_name,
        nickName: user.name,
        photo: user.pic_3,
        status: "connected",
      });

      setCurrentProvider("ok");
      context.setCurrentProvider("ok");
      localStorage.setItem("currentProvider", JSON.stringify("ok"));

      await loginSocialWebApp("ok", user);
    }
    setProgressShow(false);
  };

  const onFailureOK = (response) => {
    setProgressShow(false);
    console.error("Error:", response);
  };

  const handleSocialLogin = async (user) => {
    if (user) {
      userIdSocial = user.profileObj.googleId;
      userName = `${user.profileObj.givenName} ${
        user.profileObj.familyName ? user.profileObj.familyName : ""
      }`.trim();
      firstName = user.profileObj.givenName;
      surName = user.profileObj.familyName;
      imgFileName =
        slugify(user.profileObj.givenName) +
        "_" +
        slugify(user.profileObj.familyName);
      urlUserPicture = user.profileObj.imageUrl
        ? user.profileObj.imageUrl
        : environment.userPictureDefaultUrl;
      userMail = user.profileObj.email;
      context.setUser({
        provider: "google",
        accessToken: user.tokenObj.access_token,
        id: user.profileObj.googleId,
        firstName: user.profileObj.givenName,
        lastName: user.profileObj.familyName,
        nickName: user.profileObj.name,
        photo: user.profileObj.imageUrl,
        status: "connected",
      });
      setCurrentProvider("google");
      context.setCurrentProvider("google");
      localStorage.setItem("currentProvider", JSON.stringify("google"));
      await loginSocialWebApp("google", user);
    }
    setProgressShow(false);
  };

  const handleSocialLoginFailure = (err) => {
    console.error("handleSocialLoginFailure", err);
  };

  const handleSocialLogoutSuccess = () => {
    logout();
    setLogged(false);
    context.setLogged(false);
    localStorage.setItem("logged", JSON.stringify(false));
    setCurrentProvider("");
    context.setCurrentProvider("");
    localStorage.setItem("currentProvider", JSON.stringify(null));
  };

  const logout = async () => {
    setLogged(logged);
    setCurrentProvider("");
    if (context.userAuthenticated) {
      context.setUserAuthenticated(false);
      localStorage.setItem("userAuthenticated", JSON.stringify(false));
    }

    context.setUID(null);
    context.setFID(null);
    context.setAccessToken(null);
    context.setRefreshToken(null);
    context.setAccessTokenExpirationTime(null);
    context.setCurrentProvider(null);
    context.setUrlUserPicture(null);
    context.setUserMail(null);
    context.setUserMailing(null);
    context.setUserName(null);
    context.setSurName(null);
    context.setFirstName(null);
    context.setMiddleName(null);
    context.setStatusCheckboxpd(false);
    context.setStatusCheckboxnl(false);
    context.setLogged(false);
  };

  const regUserSocial = async (provider) => {
    const dataUser = {
      _links: {
        type: {
          href: "https://cms-headless.fund-dm.ru/rest/type/user/user",
        },
      },
      mail: [{ value: userMail }],
      name: [{ value: userName }],
      pass: [{ value: encryptedPassword }],
      field_first_name: [{ value: firstName }],
      field_middle_name: [{ value: middleName }],
      field_sur_name: [{ value: surName }],
      field_statuscheckboxpd: [{ value: statusCheckboxpd }],
      field_statuscheckboxnl: [{ value: statusCheckboxnl }],
      field_mail_for_distribution: [
        { value: provider === "google" ? userMail : "" },
      ],
    };
    await restRegUser(dataUser, tokenCSRF)
      .then((res) => {
        let resObj = JSON.parse(res);
        uid = resObj["uid"][0].value;
        context.setUID(uid);
        localStorage.setItem("UID", JSON.stringify(uid));
        oauthPasswordGrant().then(async () => {
          downloadImage().then(async () => {});
        });
        context.setUserAuthenticated(true);
        localStorage.setItem("userAuthenticated", JSON.stringify(true));
        setProgressShow(false);
      })
      .catch((error) => {
        console.log(error.message);
        setErrorMessage(
          <FormattedMessage id={"registration-error422-message2"} />
        );
        setProgressShow(false);
        setSendStatus(false);
        setErrorStatus(true);
        showMessage();
      });
  };

  const downloadImage = async () => {
    const urlDefault = environment.userPictureDefaultUrl;

    if (
      urlUserPicture !== "" &&
      urlUserPicture !== urlDefault &&
      urlUserPicture
    ) {
      let blob = await fetch(urlUserPicture).then((r) => r.blob());
      const reader = new FileReader();
      const file = blob;
      if (file) {
        currImageFile = file;
        reader.onloadend = () => {
          currImageData = reader.result;
        };
        reader.readAsDataURL(file);
        setTimeout(() => {
          if (currImageData) {
            uploadFile()
              .then(async () => {
                updateUser().then(async () => {
                  getRefreshToken().then();
                });
              })
              .catch((error) => {
                console.log(error);
                setErrorStatus(true);
              });
          }
        }, 100);
      }
    }
  };

  const uploadFile = async () => {
    if (currImageData) {
      await restUploadFile(currImageFile, tokenCSRF, accessToken, imgFileName)
        .then((res) => {
          let resObj = JSON.parse(res);
          fid = resObj["fid"][0].value;
          context.setFID(fid);
          localStorage.setItem("FID", JSON.stringify(fid));
        })
        .catch((error) => {
          setErrorStatus(true);
          console.log(error);
        });
    }
  };

  const updateUser = async () => {
    let dataWithImage = {
      _links: {
        type: {
          href: "https://cms-headless.fund-dm.ru/rest/type/user/user",
        },
      },
      user_picture: [
        {
          target_id: fid,
          description: "User picture",
        },
      ],
      name: [{ value: userName }],
      field_sur_name: [{ value: surName }],
      field_first_name: [{ value: firstName }],
      field_middle_name: [{ value: middleName }],
      field_statuscheckboxnl: [{ value: statusCheckboxnl }],
    };
    let dataWithoutImage = {
      _links: {
        type: {
          href: "https://cms-headless.fund-dm.ru/rest/type/user/user",
        },
      },
      name: [{ value: userName }],
      field_sur_name: [{ value: surName }],
      field_first_name: [{ value: firstName }],
      field_middle_name: [{ value: middleName }],
      field_statuscheckboxnl: [{ value: statusCheckboxnl }],
    };

    let data;
    currImageData ? (data = dataWithImage) : (data = dataWithoutImage);

    await restUpdateUser(data, tokenCSRF, accessToken, uid)
      .then((res) => {
        if (res && res.status === 422) {
          setErrorStatus(true);
          setErrorMessage(
            <FormattedMessage id={"registration-error422-message1"} />
          );
        } else {
          let resObj = JSON.parse(res);

          userMail = resObj["mail"] ? resObj["mail"][0].value : "";
          userMailing = resObj["field_mail_for_distribution"]
            ? resObj["field_mail_for_distribution"][0].value
            : "";
          userName = resObj["name"] ? resObj["name"][0].value : "";
          surName = resObj["field_sur_name"]
            ? resObj["field_sur_name"][0].value
            : "";
          firstName = resObj["field_first_name"]
            ? resObj["field_first_name"][0].value
            : "";
          middleName = resObj["field_middle_name"]
            ? resObj["field_middle_name"][0].value
            : "";
          statusCheckboxpd = resObj["field_statuscheckboxpd"]
            ? resObj["field_statuscheckboxpd"][0].value
            : false;
          statusCheckboxnl = resObj["field_statuscheckboxnl"]
            ? resObj["field_statuscheckboxnl"][0].value
            : false;

          if (resObj["_embedded"]) {
            urlUserPicture =
              resObj["_embedded"][
                "https://cms-headless.fund-dm.ru/rest/relation/user/user/user_picture"
              ][0]["_links"]["self"]["href"];
          } else {
            urlUserPicture = environment.userPictureDefaultUrl;
          }

          context.setUrlUserPicture(urlUserPicture);
          context.setUserMail(userMail);
          context.setUserMailing(userMailing);
          context.setUserName(userName);
          context.setSurName(surName);
          context.setFirstName(firstName);
          context.setMiddleName(middleName);
          context.setStatusCheckboxnl(statusCheckboxnl);
          context.setStatusCheckboxpd(statusCheckboxpd);

          localStorage.setItem(
            "urlUserPicture",
            JSON.stringify(urlUserPicture)
          );
          localStorage.setItem("userMail", JSON.stringify(userMail));
          localStorage.setItem("userMailing", JSON.stringify(userMailing));
          localStorage.setItem("userName", JSON.stringify(userName));
          localStorage.setItem("surName", JSON.stringify(surName));
          localStorage.setItem("firstName", JSON.stringify(firstName));
          localStorage.setItem("middleName", JSON.stringify(middleName));
          localStorage.setItem(
            "statusCheckboxpd",
            JSON.stringify(statusCheckboxpd)
          );
          localStorage.setItem(
            "statusCheckboxnl",
            JSON.stringify(statusCheckboxnl)
          );
        }
      })
      .catch((error) => {
        setErrorStatus(true);
        console.log(error);
      });
  };

  const getRefreshToken = async () => {
    let FormData = require("form-data");
    let data = new FormData();
    data.append("grant_type", "refresh_token");
    data.append("refresh_token", refreshToken);
    data.append("client_id", environment.client_id_webapp);
    data.append("client_secret", environment.client_secret_webapp);

    await restRefreshToken(data).then((res) => {
      let resObj = JSON.parse(res);
      accessTokenExpirationTime = new Date(
        currDate.setSeconds(currDate.getSeconds() + resObj.expires_in)
      );
      accessToken = resObj["access_token"];
      refreshToken = resObj["refresh_token"];
      context.setAccessToken(accessToken);
      context.setRefreshToken(refreshToken);
      context.setAccessTokenExpirationTime(accessTokenExpirationTime);
      localStorage.setItem("accessToken", JSON.stringify(accessToken));
      localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
      localStorage.setItem(
        "accessTokenExpirationTime",
        JSON.stringify(accessTokenExpirationTime)
      );
    });
  };

  const oauthPasswordGrant = async () => {
    const privateKey = environment.privateKey;
    const hashDigest = sha256(userIdSocial);
    encryptedPassword = Base64.stringify(hmacSHA512(hashDigest, privateKey));
    const hashDigestNoSocial = sha256(formik.values.password);
    encryptedPasswordNoSocial = Base64.stringify(
      hmacSHA512(hashDigestNoSocial, privateKey)
    );
    let FormDataLogin = require("form-data");
    let dataCredentialsLogin = new FormDataLogin();
    dataCredentialsLogin.append("grant_type", "password");
    dataCredentialsLogin.append("client_id", environment.client_id_webapp);
    dataCredentialsLogin.append(
      "client_secret",
      environment.client_secret_webapp
    );
    dataCredentialsLogin.append(
      "username",
      socialOAUF ? userName : formik.values.nameUser
    );
    dataCredentialsLogin.append(
      "password",
      socialOAUF ? encryptedPassword : encryptedPasswordNoSocial
    );
    return await restOauthPasswordGrant(dataCredentialsLogin).then((res) => {
      if (res?.status !== 401) {
        const result = JSON.parse(res);
        accessTokenExpirationTime = new Date(
          currDate.setSeconds(currDate.getSeconds() + result.expires_in)
        );
        accessToken = result.access_token;
        refreshToken = result.refresh_token;
        context.setAccessToken(result.access_token);
        context.setRefreshToken(result.refresh_token);
        context.setAccessTokenExpirationTime(accessTokenExpirationTime);
        localStorage.setItem("accessToken", JSON.stringify(accessToken));
        localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
        localStorage.setItem(
          "accessTokenExpirationTime",
          JSON.stringify(accessTokenExpirationTime)
        );
      }
      return res;
    });
  };

  const getUserDebug = async () => {
    await restUserDebug(accessToken).then((res) => {
      let resObj = JSON.parse(res);
      uid = resObj["id"];
      context.setUID(uid);
      localStorage.setItem("UID", JSON.stringify(uid));
    });
  };

  const getUserInfo = async () => {
    await restGetUserInfo(accessToken, uid).then((res) => {
      let resObj = JSON.parse(res);
      userMail = resObj["mail"] ? resObj["mail"][0].value : "";
      userMailing = resObj["field_mail_for_distribution"]
        ? resObj["field_mail_for_distribution"][0].value
        : "";
      userName = resObj["name"] ? resObj["name"][0].value : "";
      surName = resObj["field_sur_name"]
        ? resObj["field_sur_name"][0].value
        : "";
      firstName = resObj["field_first_name"]
        ? resObj["field_first_name"][0].value
        : "";
      middleName = resObj["field_middle_name"]
        ? resObj["field_middle_name"][0].value
        : "";
      statusCheckboxpd = resObj["field_statuscheckboxpd"]
        ? resObj["field_statuscheckboxpd"][0].value
        : false;
      statusCheckboxnl = resObj["field_statuscheckboxnl"]
        ? resObj["field_statuscheckboxnl"][0].value
        : false;

      if (resObj["_embedded"]) {
        urlUserPicture =
          resObj["_embedded"][
            "https://cms-headless.fund-dm.ru/rest/relation/user/user/user_picture"
          ][0]["_links"]["self"]["href"];
      } else {
        urlUserPicture = environment.userPictureDefaultUrl;
      }

      context.setUrlUserPicture(urlUserPicture);
      context.setUserMail(userMail);
      context.setUserMailing(userMailing);
      context.setUserName(userName);
      context.setSurName(surName);
      context.setFirstName(firstName);
      context.setMiddleName(middleName);
      context.setStatusCheckboxpd(statusCheckboxpd);
      context.setStatusCheckboxnl(statusCheckboxnl);
      localStorage.setItem("urlUserPicture", JSON.stringify(urlUserPicture));
      localStorage.setItem("userMail", JSON.stringify(userMail));
      localStorage.setItem("userMailing", JSON.stringify(userMailing));
      localStorage.setItem("userName", JSON.stringify(userName));
      localStorage.setItem("surName", JSON.stringify(surName));
      localStorage.setItem("firstName", JSON.stringify(firstName));
      localStorage.setItem("middleName", JSON.stringify(middleName));
      localStorage.setItem(
        "statusCheckboxpd",
        JSON.stringify(statusCheckboxpd)
      );
      localStorage.setItem(
        "statusCheckboxnl",
        JSON.stringify(statusCheckboxnl)
      );
    });
  };

  const validationSchema = yup.object().shape({
    nameUser: yup
      .string(<FormattedMessage id={"nameUser.required-1"} />)
      .required(<FormattedMessage id={"nameUser.required-3"} />),
    password: yup
      .string(<FormattedMessage id={"password.required-1"} />)
      .required(<FormattedMessage id={"password.required-3"} />),
  });

  const formik = useFormik({
    initialValues: {
      nameUser: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      setProgressShow(true);
      await oauthPasswordGrant()
        .then(async (res) => {
          await getUserDebug();
          await getUserInfo();
          setProgressShow(false);
          setSendStatus(true);
          setErrorStatus(false);
          showMessage();
          socialOAUF = false;
        })
        .catch(() => {
          setErrorMessage(
            <FormattedMessage id={"registration-error422-message2"} />
          );
          setSendStatus(false);
          setErrorStatus(true);
          setProgressShow(false);
        });
      formik.resetForm();
    },
  });

  return (
    <div className="login">
      <img
        className={context.userAuthenticated ? "show" : "hide"}
        src={context.urlUserPicture}
        alt={context.userName}
      />
      <div
        className={
          context.userAuthenticated ? "welcome-block-auth show" : "hide"
        }
      >
        <div className="text-block-auth">
          <img
            className={context.userAuthenticated ? "show" : "hide"}
            src={context.urlUserPicture}
            alt={context.userName}
          />
          <div className="text-center">
            <div className="card-text mt-2 mb-2">
              <div className="user-name">{context.userName}</div>
              <div className="user-mail">{context.userMailing}</div>
            </div>
            <p className="text-message">
              <FormattedMessage id={"message-welcome"} />
            </p>
            <h4 className="text-message2">
              <FormattedMessage id={"slogan2"} />!
            </h4>
            <div className="button-wrap mt-3 mb-3">
              <Link to={"/user-account/" + context.UID}>
                <Button
                  className="me-1"
                  variant="contained"
                  color="primary"
                  endIcon={<AccountCircleIcon />}
                >
                  <FormattedMessage id={"user-personal-account"} />
                </Button>
              </Link>
              {currentProvider === "google" ? (
                <GoogleLogout
                  className="btn-exit-google"
                  clientId={clientGoogleId}
                  buttonText={<FormattedMessage id={"exit"} />}
                  onLogoutSuccess={handleSocialLogoutSuccess}
                />
              ) : (
                <Button
                  className="btn-exit"
                  variant="outlined"
                  endIcon={<ExitToAppIcon />}
                  onClick={logout}
                >
                  <FormattedMessage id={"exit"} />
                </Button>
              )}
            </div>
            <div className="group-text">
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://cms-headless.fund-dm.ru/sites/default/files/2021-09/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%B1%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf"
                >
                  <SecurityIcon className="me-2" />
                  <FormattedMessage id={"security-policy"} />
                </a>
              </p>
              <p>
                <Link to={"/user-agreement"}>
                  <PublicIcon className="me-2" />
                  <FormattedMessage id={"user-agreement"} />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          !context.userAuthenticated ? "wrap-login-block show" : "hide"
        }
      >
        <div className="login-block">
          <AccountCircleIcon />
          <div
            className={
              !context.userAuthenticated
                ? "welcome-block text-center show"
                : "hide"
            }
          >
            <div className="text-block">
              <p className="mb-1">
                <FormattedMessage id={"social-auth-message-1"} />
              </p>
              <p>
                <FormattedMessage id={"social-auth-message-2"} />
              </p>
              <h3 style={{ color: "var(--blue-dark)" }}>
                <FormattedMessage id={"social-auth-message-3"} />
              </h3>
              <div>
                <ThumbUpAltRoundedIcon className="icon" />
              </div>
              <hr className="hr-style2" />
              <section className="social-icons mt-3">
                <SocialLogin
                  authorizationUrl={`https://oauth.vk.com/authorize?client_id=${environment.client_id_vk}&display=page&response_type=code&v=5.131&redirect_uri=${REDIRECT_URI}`}
                  responseType="token"
                  clientId={environment.client_id_vk}
                  redirectUri={REDIRECT_URI}
                  onSuccess={onSuccessVK}
                  onFailure={onFailureVK}
                  render={(renderProps) => (
                    <div
                      onClick={() => {
                        renderProps.onClick();
                        setCurrentProvider("vk");
                        setProgressShow(true);
                      }}
                      className="soc-vk"
                    >
                      <Tooltip
                        title={
                          context.locale === "en-US"
                            ? "Login via VK"
                            : "Войти через Вконтакте"
                        }
                        TransitionComponent={Zoom}
                        arrow
                      >
                        <article>
                          <img src={Vk} alt="Vk icon" />
                        </article>
                      </Tooltip>
                    </div>
                  )}
                />

                <SocialLogin
                  authorizationUrl={`https://connect.ok.ru/oauth/authorize?client_id=${environment.client_id_ok}&layout=a&scope=VALUABLE_ACCESS&response_type=token&redirect_uri=${REDIRECT_URI}`}
                  responseType="token"
                  clientId={environment.client_id_ok}
                  redirectUri={REDIRECT_URI}
                  onSuccess={onSuccessOK}
                  onFailure={onFailureOK}
                  render={(renderProps) => (
                    <div
                      onClick={() => {
                        renderProps.onClick();
                        setCurrentProvider("ok");
                        setProgressShow(true);
                      }}
                      className="soc-ok"
                    >
                      <Tooltip
                        title={
                          context.locale === "en-US"
                            ? "Login via Odnoklassniki"
                            : "Войти через Одноклассники"
                        }
                        TransitionComponent={Zoom}
                        arrow
                      >
                        <article>
                          <img src={Ok} alt="Ok icon" />
                        </article>
                      </Tooltip>
                    </div>
                  )}
                />

                <GoogleLogin
                  clientId={clientGoogleId}
                  onSuccess={handleSocialLogin}
                  onFailure={handleSocialLoginFailure}
                  render={(renderProps) => (
                    <div className="google" onClick={renderProps.onClick}>
                      <Tooltip
                        title={
                          context.locale === "en-US"
                            ? "Login via Google"
                            : "Войти через Google"
                        }
                        TransitionComponent={Zoom}
                        arrow
                      >
                        <article>
                          <img src={Google} alt="Google icon" />
                        </article>
                      </Tooltip>
                    </div>
                  )}
                  cookiePolicy={"single_host_origin"}
                  isSignedIn={true}
                />

                {/* <SocialButton
                  className="google"
                  provider="google"
                  appId="231900329210-mldcllpj1ou2t0v5sik84od3ktc3cqs7.apps.googleusercontent.com"
                  onLoginSuccess={handleSocialLogin}
                  onLoginFailure={handleSocialLoginFailure}
                  onLogoutSuccess={handleSocialLogoutSuccess}
                  onLogoutFailure={handleSocialLogoutFailure}
                  getInstance={setNodeRef.bind(this, "google")}
                  key={"google"}
                >
                  <Tooltip
                    title={
                      context.locale === "en-US"
                        ? "Login via Google"
                        : "Войти через Google"
                    }
                    TransitionComponent={Zoom}
                    arrow
                  >
                    <article>
                      <img src={Google} alt="Google icon" />
                    </article>
                  </Tooltip>
                </SocialButton> */}
              </section>
              <hr />
              <Collapse in={sendStatus}>
                <Alert
                  severity="success"
                  onClick={() => {
                    setSendStatus(false);
                  }}
                >
                  <AlertTitle>
                    <p className="message">
                      <FormattedMessage id={"logged-in-success-message"} />
                    </p>
                  </AlertTitle>
                </Alert>
              </Collapse>
              <Collapse in={errorStatus}>
                <Alert
                  severity="error"
                  onClick={() => {
                    setErrorStatus(false);
                  }}
                >
                  <AlertTitle>
                    <p className="message">{errorMessage}</p>
                  </AlertTitle>
                </Alert>
              </Collapse>
              <LinearProgress className={progressShow ? "show" : "hide"} />

              <form onSubmit={formik.handleSubmit}>
                <TextField
                  name="nameUser"
                  type="text"
                  label={<FormattedMessage id={"nameUser.required-1"} />}
                  value={formik.values.nameUser}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.nameUser && Boolean(formik.errors.nameUser)
                  }
                  helperText={formik.touched.nameUser && formik.errors.nameUser}
                  variant="filled"
                />
                <TextField
                  name="password"
                  type={showPassword ? "text" : "password"}
                  label={<FormattedMessage id={"password.required-1"} />}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  variant="filled"
                />
                <Tooltip
                  title={
                    showPassword
                      ? context.locale === "ru-RU"
                        ? "Скрыть пароль"
                        : "Hide password"
                      : context.locale === "ru-RU"
                      ? "Показать пароль"
                      : "Show password"
                  }
                  TransitionComponent={Zoom}
                  arrow
                >
                  <VisibilityIcon
                    className={
                      showPassword ? "visible-icon show" : "visible-icon hide"
                    }
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </Tooltip>
                <br />
                <Button
                  className="btn-orange"
                  variant="contained"
                  color="primary"
                  type="submit"
                  endIcon={<Icon>send</Icon>}
                >
                  <FormattedMessage id={"send"} />
                </Button>
                <Link to="/user-registration">
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<HowToRegIcon />}
                  >
                    <FormattedMessage id={"register-now"} />
                  </Button>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
