import React from "react";
import { Col } from "react-bootstrap";

import { Skeleton, Box, Avatar, Typography } from "@mui/material";

const SceletonCivilInitiatives = () => {
  return (
    <>
      <Col lg={3} sm={6} md={6} col={6} xs={6} className={"mb-1"}>
        <Box display="flex" alignItems="center" className={"mb-1"}>
          <Box margin={1}>
            <Skeleton
              className="rounded-circle"
              variant="circle"
              animation={"wave"}
            >
              <Avatar />
            </Skeleton>
          </Box>
          <Box width="100%">
            <Skeleton animation={"wave"} width="100%">
              <Typography>.</Typography>
            </Skeleton>
            <Skeleton animation={"wave"} height={10} width={"30%"}>
              <Typography>.</Typography>
            </Skeleton>
          </Box>
        </Box>
        <Skeleton
          className={"mb-2"}
          variant="rect"
          animation={"wave"}
          width="100%"
        >
          <div style={{ paddingTop: "50%" }} />
        </Skeleton>
        <Skeleton
          animation={"wave"}
          height={10}
          width={"50%"}
          style={{ left: "50%", marginLeft: "-25%" }}
        />
        <Skeleton
          animation={"wave"}
          height={10}
          width={"75%"}
          style={{ left: "50%", marginLeft: "-38%" }}
        />
        <Skeleton
          animation={"wave"}
          height={10}
          width={"50%"}
          style={{ left: "50%", marginLeft: "-25%" }}
        />
        <Skeleton
          animation={"wave"}
          height={10}
          width={"75%"}
          style={{ left: "50%", marginLeft: "-38%" }}
        />
        <Skeleton
          animation={"wave"}
          height={10}
          width={"50%"}
          style={{ left: "50%", marginLeft: "-25%" }}
        />
        <Skeleton className={"mt-1"} animation={"wave"} width={"40%"} />
      </Col>
    </>
  );
};

export default SceletonCivilInitiatives;
