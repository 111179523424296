import React from "react";
import { Skeleton } from "@mui/material";

const SceletonPageInner = () => {
  return (
    <>
      <h1>
        <Skeleton
          style={{ margin: "0 30%" }}
          animation={"wave"}
          variant="text"
        />
      </h1>
      <Skeleton
        className="mb-2"
        style={{ borderRadius: "10px", paddingTop: "50%", margin: "0 5%" }}
        variant={"rect"}
        animation={"wave"}
      />
      <p>
        <Skeleton animation={"wave"} />
        <Skeleton animation={"wave"} />
        <Skeleton animation={"wave"} />
        <Skeleton animation={"wave"} />
        <Skeleton animation={"wave"} />
        <Skeleton animation={"wave"} width={"25%"} />
      </p>
    </>
  );
};

export default SceletonPageInner;
