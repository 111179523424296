import axios from "axios";
import environment from "../../environments/environment";

const restGetUserInfo = async (accessToken, uid) => {
  const config = {
    method: "get",
    url: environment.cms_headless_url + "/user/" + uid + "?_format=hal_json",
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };

  return axios(config)
    .then((response) => {
      return JSON.stringify(response.data);
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export default restGetUserInfo;
