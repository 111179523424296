import React from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const News = (props) => {

  const data = props.data
  return (
    <>
      {
        data &&
        <div className="solutions-box">
          <div className="blog-image">
            <Link to={'/news/page/' + data.nid}>
              <img src={data.field_photo_announcement} alt="imgItem1"/>
            </Link>
          </div>
          <small>
            <Link className="mt-1" to={'/news/page/' + data.nid} >
              <FormattedDate
                value={data.created}
                day="numeric"
                month="short"
                year="numeric"
              />
            </Link>
          </small>
          <h3>
            <Link className="mt-1" to={'/news/page/' + data.nid}>
              {data.title}
            </Link>
          </h3>
          <Link className="learn-more-btn" to={'/news/page/' + data.nid}>
            <svg
              xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
              className="feather feather-plus-circle"
            >
              <circle cx="12" cy="12" r="10" />
              <line x1="12" y1="8" x2="12" y2="16" />
              <line x1="8" y1="12" x2="16" y2="12" />
            </svg>
            <span id={"read-more"}> <FormattedMessage id={"read-more"} /></span>
          </Link>
        </div>
      }
    </>
  )
}

export default News
