import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Boss from "../../../../assets/image/yakunin_aa.jpg";
import ScrollAnimation from "react-animate-on-scroll";
import { useInView } from "react-intersection-observer";
import { FormattedMessage } from "react-intl";

import "./Section-about-us.sass";

const SectionAboutUs = () => {
  const refPhone = useRef(null);
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  return (
    <section id="section-about-us" className="section-about-us">
      <Container>
        <Row>
          <Col sm={6} className="image-container" ref={refPhone}>
            <ScrollAnimation animateIn="fadeInLeft">
              <div className="wrap-img">
                <img className="img" src={Boss} alt="Yakunin A.A." />
              </div>
            </ScrollAnimation>
          </Col>
          <Col sm={6} className="content-container" ref={ref}>
            <div className={inView ? "content in-view" : "content"}>
              <h2>
                <strong>
                  <FormattedMessage id={"appeal"} />
                </strong>
                <br />
                <FormattedMessage id={"appeal-describe"} />
              </h2>
              <p>
                <FormattedMessage id={"appeal-describe-continuation"} />
              </p>
              <p>
                <Link
                  to={"/about-us"}
                  className={inView ? "button arrow in-view" : "button arrow"}
                >
                  <FormattedMessage id={"read-more"} />
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SectionAboutUs;
