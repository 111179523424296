import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import { Icon24LogoVk, Icon28InboxOutline } from "@vkontakte/icons";
import { FormattedMessage } from "react-intl";

import EmailIcon from "@mui/icons-material/Email";
import MoneyIcon from "@mui/icons-material/Money";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PublicIcon from "@mui/icons-material/Public";
import SecurityIcon from "@mui/icons-material/Security";

import FeedbackModal from "../modal/feedback/Feedback-modal";
import "./Footer.sass";

const Footer = () => {
  const sectionVariant = {
    hidden: {
      y: "100vh",
    },
    visible: {
      y: 0,
      transition: { delay: 0.3, duration: 1, type: "tween" },
    },
  };

  function Mailto({ email, subject, body, ...props }) {
    return (
      <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
        {props.children}
      </a>
    );
  }

  let navigate = useNavigate();
  let location = useLocation();

  const handleClick = (anchor, position) => {
    const pathName = location.pathname;
    const hashName = location.hash;
    if (pathName === "/" || hashName.includes("/#")) {
      let anchorEle = document.getElementById(anchor);
      anchorEle.scrollIntoView({ behavior: "smooth", block: position });
    } else {
      console.log("anchor", anchor);
      navigate(`/#` + anchor, { replace: true });
    }
  };

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <motion.section
        className="footer"
        variants={sectionVariant}
        initial="hidden"
        animate="visible"
      >
        <div className="section-top mb-4">
          <Container>
            <Row>
              <Col
                md={12}
                lg={6}
                xl={6}
                className="block-buttons text-lg-end text-sm-center text-md-center align-items-center"
              >
                <span
                  className="post"
                  onClick={() => {
                    setModalShow(true);
                  }}
                >
                  <span
                    className="border rounded p-2 px-3 me-3 d-md-inline-block"
                    style={{ cursor: "pointer" }}
                  >
                    <FormattedMessage id={"mail"} />
                    <EmailIcon className="ms-2" />
                  </span>
                </span>
                <Link to="donate">
                  <span className="border rounded p-2 px-3 d-md-inline-block">
                    <FormattedMessage id={"donations"} />
                    <MoneyIcon className="ms-2" />
                  </span>
                </Link>
              </Col>
              <Col
                md={12}
                lg={6}
                xl={6}
                className="d-lg-flex text-sm-center text-md-center align-items-center"
              >
                <span className="block-social-icons align-items-center">
                  <span className="icon-social">
                    <a
                      href="https://vk.com/miloserdie72"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon24LogoVk width={26} height={26} />
                    </a>
                  </span>
                  <span className="icon-social">
                    <a
                      href="https://www.youtube.com/channel/UCFv2_VthtAwOJZDuqRVtfvQ"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <YouTubeIcon style={{ fontSize: "2em" }} />
                    </a>
                  </span>
                  <span className="icon-social">
                    <a
                      href="https://roundcube.hosting.reg.ru"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon28InboxOutline width={26} height={26} />
                    </a>
                  </span>
                </span>
              </Col>
            </Row>
            <hr className="hr-style1" />
            <Row>
              <Col className="group-text mb-4" md={3} lg={3} xl={3}>
                <h6>
                  <FormattedMessage id={"about-us"} />
                </h6>
                <hr className="hr-style2" />
                <p
                  onClick={() => {
                    handleClick("section-about-us", "center");
                  }}
                >
                  <Link to="#about-us">
                    <FormattedMessage id={"about-fund"} />
                  </Link>
                </p>
                <p
                  onClick={() => {
                    handleClick("section-news", "end");
                  }}
                >
                  <Link to="#news">
                    <FormattedMessage id={"news"} />
                  </Link>
                </p>
              </Col>
              <Col className="group-text mb-4" md={3} lg={3} xl={3}>
                <h6>
                  <FormattedMessage id={"activities"} />
                </h6>
                <hr className="hr-style2" />
                <p>
                  <Link to={"/projects"}>
                    <FormattedMessage id={"projects"} />
                  </Link>
                </p>
                <p>
                  <Link to={"/civil-initiatives"}>
                    <FormattedMessage id={"civil-initiatives"} />
                  </Link>
                </p>
                <p>
                  <Link to={"/targeted-assistance"}>
                    <FormattedMessage id={"targeted-assistance"} />
                  </Link>
                </p>
                <p>
                  <Link to={"/become-volunteer"}>
                    <FormattedMessage id={"become-volunteer"} />
                  </Link>
                </p>
              </Col>
              <Col className="group-text mb-4" md={3} lg={3} xl={3}>
                <h6>
                  <FormattedMessage id={"documents"} />
                </h6>
                <hr className="hr-style2" />
                <p>
                  <Link to={"/reports"}>
                    <FormattedMessage id={"reports"} />
                  </Link>
                </p>
                <p>
                  <Link to={"/constituent-documents"}>
                    <FormattedMessage id={"constituent"} />
                  </Link>
                </p>
                <p>
                  <Link to={"/agreements"}>
                    <FormattedMessage id={"agreements"} />
                  </Link>
                </p>
                <p>
                  <Link to={"/conventions"}>
                    <FormattedMessage id={"conventions"} />
                  </Link>
                </p>
                <p>
                  <Link to={"/policies"}>
                    <FormattedMessage id={"policies"} />
                  </Link>
                </p>
                <p>
                  <Link to={"/printing-materials"}>
                    <FormattedMessage id={"printing-materials"} />
                  </Link>
                </p>
              </Col>
              <Col className="group-text mb-4" md={3} lg={3} xl={3}>
                <h6>
                  <FormattedMessage id={"information"} />
                </h6>
                <hr className="hr-style2" />
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://cms-headless.fund-dm.ru/sites/default/files/2021-09/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%B1%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf"
                  >
                    <SecurityIcon className="me-2" />
                    <FormattedMessage id={"security-policy"} />
                  </a>
                </p>
                <p>
                  <Link to={"/user-agreement"}>
                    <PublicIcon className="me-2" />
                    <FormattedMessage id={"user-agreement"} />
                  </Link>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section-bottom text-center">
          <div className="wrapper">
            <span>Development by Korobeinikov Egor</span>
            <span>
              <Mailto
                email="egorlo059@gmail.com"
                subject="Письмо от"
                body="Здравствуйте,"
              >
                egorlo059@gmail.com
              </Mailto>
            </span>
          </div>
        </div>
      </motion.section>
      <FeedbackModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Footer;
