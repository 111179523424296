import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormattedDate, FormattedMessage } from "react-intl";

import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  TextField,
  Alert,
  AlertTitle,
} from "@mui/material";

import makeStyles from "@emotion/styled";
import { blue } from "@mui/material/colors";

import Logo from "../../../assets/image/logo/logo.svg";
import environment from "../../../environments/environment";
import "./Feedback-modal.sass";

const FeedbackModal = (props) => {
  const [open, setOpen] = useState(false);

  const useStyles = makeStyles(() => ({
    avatar: {
      width: 70,
      height: 70,
      backgroundColor: blue[300],
    },
  }));

  const validationSchema = yup.object().shape({
    name: yup
      .string(<FormattedMessage id={"name.required-1"} />)
      .min(4, <FormattedMessage id={"name.required-2"} />)
      .required(<FormattedMessage id={"name.required-3"} />),
    email: yup
      .string(<FormattedMessage id={"email.required-1"} />)
      .email(<FormattedMessage id={"email.required-2"} />)
      .required(<FormattedMessage id={"email.required-3"} />),
    message: yup
      .string(<FormattedMessage id={"text.required-1"} />)
      .min(25, <FormattedMessage id={"text.required-2"} />)
      .required(<FormattedMessage id={"text.required-3"} />),
  });

  const classes = useStyles();
  const currDate = Date();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const xhr = new XMLHttpRequest();
      xhr.addEventListener("load", () => {
        if (xhr.responseText !== null) {
          if (xhr.status === 200) {
            const interval = setInterval(() => {
              setOpen(false);
            }, 5000);
            return () => {
              clearInterval(interval);
            };
          }
        }
      });
      xhr.open(
        "GET",
        environment.emailUrl +
          "?sendto=" +
          values.email +
          "&name=" +
          values.name +
          "&message=" +
          values.message
      );
      xhr.send();
      formik.resetForm();
      setOpen(true);
    },
  });

  return (
    <Modal
      {...props}
      className="backdrop"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="modal-feedback">
        <Card>
          <Collapse in={open}>
            <Alert
              severity="success"
              onClick={() => {
                setOpen(false);
              }}
            >
              <AlertTitle>
                <FormattedMessage id={"alert-mail.success"} />
              </AlertTitle>
            </Alert>
          </Collapse>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                <img src={Logo} alt="Logo" />
              </Avatar>
            }
            title={
              <span>
                <FormattedMessage id={"feedback.title"} />
              </span>
            }
            subheader={
              <FormattedDate
                value={currDate}
                day="numeric"
                month="short"
                year="numeric"
              />
            }
          />
          <CardContent>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label={<FormattedMessage id={"name.required-1"} />}
                variant="outlined"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                fullWidth
                id="email"
                name="email"
                label={<FormattedMessage id={"email.required-1"} />}
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                fullWidth
                multiline
                id="message"
                name="message"
                label={<FormattedMessage id={"text.required-1"} />}
                variant="outlined"
                value={formik.values.message}
                rows={5}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
              />
              <Button
                fullWidth
                color="primary"
                variant="contained"
                className="mb-1"
                type="submit"
              >
                <FormattedMessage id={"send"} />
              </Button>
              <Button fullWidth variant="contained" onClick={props.onHide}>
                <FormattedMessage id={"close"} />
              </Button>
            </form>
          </CardContent>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default FeedbackModal;
