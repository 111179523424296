import React from "react";
import { motion } from "framer-motion";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import FeedbackModal from "../../modal/feedback/Feedback-modal";
import Clip from "../../../assets/video/clip.mp4";
import DownAngleContainer from "../down-angle-container/Down-angle-container";

import "./Video-section.sass";

const VideoSection = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <motion.section
        className="video-section element-in-view-trigger in-view"
        initial={{ y: "-100vh", height: 0 }}
        animate={{ y: 0, height: "100vh" }}
        transition={{ duration: 0.7, type: "tween" }}
      >
        <div className="video-content">
          <h1>
            <motion.span
              className="title"
              initial={{ x: -125, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.5, duration: 1, type: "tween" }}
            >
              <FormattedMessage id={"subtitle"} />
            </motion.span>
            <motion.span
              className="subtitle"
              initial={{ x: 125, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.5, duration: 1, type: "tween" }}
            >
              <FormattedMessage id={"subtitle2"} />
            </motion.span>
          </h1>
          <motion.div
            className="colorbar"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.9, type: "tween" }}
          >
            <span />
          </motion.div>
          <motion.div
            className="title2"
            initial={{ y: -40, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 1.1, type: "spring", mass: 0.5, damping: 6 }}
          >
            <h1>
              <FormattedMessage id={"title"} />
            </h1>
            <div className="group-btn">
              <p>
                <Link to={"/donate"}>
                  <span className="button color-d cta-form-btn">
                    <FormattedMessage id={"financial-help"} />
                  </span>
                </Link>
              </p>
              <p>
                <Link to={"/become-volunteer"}>
                  <span className="button color-d cta-form-btn">
                    <FormattedMessage id={"become-volunteer"} />
                  </span>
                </Link>
              </p>
            </div>
          </motion.div>
        </div>
        <motion.video
          autoPlay
          muted
          loop
          playsInline
          className="video loaded"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 1 }}
        >
          <source src={Clip} type="video/mp4" />
          <div className="source" video-data-src={Clip} />
        </motion.video>
        <DownAngleContainer />
      </motion.section>
      <FeedbackModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default VideoSection;
