import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { motion } from "framer-motion";

import { Tooltip, Zoom } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ReplyIcon from "@mui/icons-material/Reply";

import { Context } from "../../contexts/Context";
import Error404 from "../../../assets/image/Error404.jpg";
import DownAngleInnerPage from "../down-angle-inner-page/Down-angle-inner-page";
import "./Page-not-found.sass";

const variantsSection = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      duration: 0.7,
    },
  },
};

const variantsFooter = {
  hidden: {
    opacity: 0,
    y: "20%",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.5,
      duration: 0.7,
      type: "tween",
    },
  },
};

const PageNotFound = () => {
  const context = useContext(Context);
  let navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  useEffect(() => {
    document.body.scrollIntoView({ behavior: "auto", block: "start" });
  }, []);

  return (
    <>
      <DownAngleInnerPage title={"Страница не найдена"} />
      <motion.section
        className="page-404"
        variants={variantsSection}
        initial="hidden"
        animate="visible"
      >
        <Container>
          <Row>
            <Col className="text-center mt-0 mb-5">
              <img style={{ maxWidth: "100%" }} src={Error404} alt="Error404" />
              <div className="ms-4 mt-4">
                <Tooltip
                  title={
                    context.locale === "ru-RU" ? "На главную" : "To the main"
                  }
                  TransitionComponent={Zoom}
                  arrow
                >
                  <button>
                    <Link to={""}>
                      <HomeIcon color="primary" />
                    </Link>
                  </button>
                </Tooltip>
                <Tooltip
                  title={
                    context.locale === "ru-RU"
                      ? "На предыдущую"
                      : "To the previous"
                  }
                  TransitionComponent={Zoom}
                  arrow
                >
                  <button onClick={() => goBack()}>
                    <ReplyIcon color="primary" />
                  </button>
                </Tooltip>
              </div>
            </Col>
          </Row>
        </Container>
        <motion.div variants={variantsFooter}></motion.div>
      </motion.section>
    </>
  );
};

export default PageNotFound;
