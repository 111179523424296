import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

import DonateRu from "../../../../assets/image/donate.jpg";
import DonateEn from "../../../../assets/image/donate-en.jpg";
import { Context } from "../../../contexts/Context";
import DonateBlock from "../donate-block/Donate-block";
import "./Donate-section.sass";

const DonateSection = (props) => {
  const context = useContext(Context);
  const [donate, setDonate] = useState(DonateRu);

  useEffect(() => {
    if (context.locale === "en-US") {
      setDonate(DonateEn);
    } else {
      setDonate(DonateRu);
    }
  }, [context]);

  return (
    <section className="donate-section text-start mt-4">
      <Row>
        <Col lg={6}>
          <img className="mb-1" src={donate} alt="img-donate" />
          <DonateBlock title={props.title} />
        </Col>
        <Col className="col-1 d-none d-xl-block" />
        <Col className="bank-details" lg={6} xl={5}>
          <h2 className="d-title text-left">
            <FormattedMessage id={"donate-title"} />
          </h2>
          <hr className="hr-style1 mb-3" />
          <ul className="text-left">
            <li className="mb-2 name-fund">
              <span className="mr-2">
                <FormattedMessage id={"full-title"} />
              </span>
            </li>
            <li>
              <span className="mr-2">
                <FormattedMessage id={"bank-details.inn"} />
              </span>{" "}
              7203519644;
            </li>
            <li>
              <span className="mr-2">
                <FormattedMessage id={"bank-details.kpp"} />
              </span>{" "}
              720301001;
            </li>
            <li>
              <span className="mr-2">
                <FormattedMessage id={"bank-details.ogrn"} />
              </span>{" "}
              1217200007408;
            </li>
            <li>
              <span className="mr-2">
                <FormattedMessage id={"bank-details.okved"} />
              </span>{" "}
              64.99.6;
            </li>
            <li>
              {" "}
              <span className="mt-2">
                <FormattedMessage id={"bank"} />
              </span>
              <FormattedMessage id={"bank-name"} />
            </li>
            <li>
              <span className="mr-2">
                <FormattedMessage id={"bank-details.current-account"} />
              </span>
              40703810600000718755
            </li>
            <li>
              <span className="mr-2">
                <FormattedMessage id={"bank-details.correspondent-account"} />
              </span>{" "}
              30101810145250000974;
            </li>
            <li>
              <span className="mr-2">
                <FormattedMessage id={"bank-details.bik"} />
              </span>{" "}
              044525974;
            </li>
            <li>
              <span className="mr-2">
                <FormattedMessage id={"bank-details.purpose-payment"} />
              </span>
              <FormattedMessage id={"bank-details.purpose-payment-target"} />
            </li>
            <li className="mt-2">
              <span>
                <FormattedMessage id={"bank-details.nds"} />
              </span>
            </li>
            <li className="mt-2">
              <LocationOnIcon />
              <FormattedMessage id={"our-contacts.address"} />{" "}
            </li>
            <li className="mt-1">
              <PhoneIphoneIcon />
              +(7)&nbsp;904-499-3434
            </li>
            <li className="mt-1">
              <AlternateEmailIcon /> bux@fund-dm.ru
            </li>
          </ul>
        </Col>
      </Row>
    </section>
  );
};

export default DonateSection;
