import environment from "../environments/environment"

const changePath = (data) => {
  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].field_photo_announcement) data[i].field_photo_announcement = environment.cms_headless_url + data[i].field_photo_announcement
      if (data[i].field_photo) data[i].field_photo = data[i].field_photo.replace(new RegExp(`/sites/default`, 'g'), environment.cms_headless_url + `/sites/default`)
      if (data[i].body) data[i].body = data[i].body.replace(new RegExp(`src="/sites/default`, 'g'), `src="` + environment.cms_headless_url + `/sites/default`)
      if (data[i].field_document_1) data[i].field_document_1 = data[i].field_document_1.replace(new RegExp(`/sites/default`, 'g'), environment.cms_headless_url + `/sites/default`)
    }
  }
  return data
}

export default changePath
