import React, { useState, createContext, useEffect } from "react";
import { IntlProvider } from "react-intl";

import English from "../../languages/en-US.json";
import Russian from "../../languages/ru-RU.json";
import { useLocalStorage } from "../localStorage/use-localStorage";

export const Context = createContext([]);
const local = navigator.language;

const ContextProvider = (props) => {
  const [locale, setLocale] = useState("ru-RU");
  let lang;
  if (local === "en-US") {
    lang = English;
  } else {
    lang = Russian;
  }

  const [user, setUser] = useState({});

  const [messages, setMessages] = useState(lang);
  const [typePage, setTypePage] = useState(null);
  const [userAuthenticated, setUserAuthenticated] = useLocalStorage(
    "userAuthenticated",
    false
  );
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
  const [refreshToken, setRefreshToken] = useLocalStorage("refreshToken", null);
  const [accessTokenExpirationTime, setAccessTokenExpirationTime] =
    useLocalStorage("accessTokenExpirationTime", null);
  const [UID, setUID] = useLocalStorage("UID", null);
  const [FID, setFID] = useLocalStorage("FID", null);
  const [urlUserPicture, setUrlUserPicture] = useLocalStorage(
    "urlUserPicture",
    null
  );

  const [userMail, setUserMail] = useLocalStorage("userMail", null);
  const [userMailing, setUserMailing] = useLocalStorage("userMailing", null);
  const [userName, setUserName] = useLocalStorage("userName", null);
  const [surName, setSurName] = useLocalStorage("surName", null);
  const [firstName, setFirstName] = useLocalStorage("firstName", null);
  const [middleName, setMiddleName] = useLocalStorage("middleName", null);
  const [statusCheckboxpd, setStatusCheckboxpd] = useLocalStorage(
    "statusCheckboxpd",
    false
  );
  const [statusCheckboxnl, setStatusCheckboxnl] = useLocalStorage(
    "statusCheckboxnl",
    false
  );
  const [logged, setLogged] = useLocalStorage("logged", false);
  const [currentProvider, setCurrentProvider] = useLocalStorage(
    "currentProvider",
    null
  );

  const selectLang = () => {
    if (locale === "en-US") {
      setLocale("ru-RU");
      setMessages(Russian);
    } else {
      setLocale("en-US");
      setMessages(English);
    }
  };

  const selectTypePage = (val) => {
    setTypePage(val);
  };

  const errorsHandler = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (!userAuthenticated) localStorage.clear();
  }, [userAuthenticated]);

  return (
    <Context.Provider
      value={{
        user,
        setUser,
        locale,
        selectLang,
        typePage,
        selectTypePage,
        userAuthenticated,
        setUserAuthenticated,
        urlUserPicture,
        setUrlUserPicture,
        userName,
        setUserName,
        surName,
        setSurName,
        firstName,
        setFirstName,
        middleName,
        setMiddleName,
        statusCheckboxpd,
        setStatusCheckboxpd,
        statusCheckboxnl,
        setStatusCheckboxnl,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        accessTokenExpirationTime,
        setAccessTokenExpirationTime,
        UID,
        setUID,
        FID,
        setFID,
        userMail,
        setUserMail,
        userMailing,
        setUserMailing,
        logged,
        setLogged,
        currentProvider,
        setCurrentProvider,
      }}
    >
      <IntlProvider onError={errorsHandler} locale={locale} messages={messages}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default ContextProvider;
