import React from "react";

import VideoSection from "../video-section/Video-section";
import ScrollingText from "../scrolling-text/Scrolling-text";
import SectionAboutUs from "./section-about-us/Section-about-us";
import SectionActivities from "./section-activities/Section-activities";
import SectionSlogan from "./section-slogan/Section-slogan";
import SectionNews from "./section-news/Section-news";
import SectionOurTeam from "./section-our-team/Section-our-team";
import SectionContacts from "./section-contacts/Section-contacts";

import "./Main-section.sass";

const MainSection = () => {
  return (
    <section className="section-main">
      <VideoSection />
      <ScrollingText />
      <SectionAboutUs />
      <SectionActivities />
      <SectionSlogan />
      <SectionNews />
      <SectionOurTeam />
      <SectionContacts />
    </section>
  );
};

export default MainSection;
