import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";

import { Tooltip, Zoom, Pagination } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ReplyIcon from "@mui/icons-material/Reply";

import { Context } from "../../../../contexts/Context";
import DownAngleInnerPage from "../../../../pages-inner/down-angle-inner-page/Down-angle-inner-page";
import TargetedAssistance from "../../../../items/targeted-assistance/Targeted-assistance";
import environment from "../../../../../environments/environment";
import SceletonTargetedAssistance from "../../../../sceletons/sceleton-targeted-assistance";
import fetcher from "../../../../../rest/fetcher";
import "./Page-targeted-assistance.sass";

const variantsSection = {
  hidden: {
    opacity: 0,
    y: "20vh",
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
    },
  },
};

const PageTargetedAssistance = () => {
  const context = useContext(Context);
  const navigate = useNavigate();

  const url =
    environment.cms_headless_url +
    "/api/rest/node?_format=json&field_type_page_target_id=Адресная помощь&langcode=";

  const { data, error } = useSWR(
    context.locale === "en-US" ? url + "en" : url + "ru",
    fetcher
  );

  const [itemsTargetedAssistance, setItemsTargetedAssistance] = useState([]);
  const [currItem, setCurrItem] = useState(1);
  const [itemsPer] = useState(8);

  // Get current items
  const indexOfLast = currItem * itemsPer;
  const indexOfFirst = indexOfLast - itemsPer;
  const currItemsTargetedAssistance = itemsTargetedAssistance.slice(
    indexOfFirst,
    indexOfLast
  );

  // Change page TargetedAssistance
  const handleChangeTargetedAssistance = (event, value) => {
    setCurrItem(value);
  };

  function goBack() {
    navigate(-1);
  }

  useEffect(() => {
    if (data) {
      setItemsTargetedAssistance(data);
    }
  }, [data]);

  useEffect(() => {
    document.body.scrollIntoView({ behavior: "auto", block: "start" });
  }, []);

  let scetetonList = [];
  for (let i = 0; i < 8; i++) {
    scetetonList.push(<SceletonTargetedAssistance key={i} />);
  }

  if (error) return console.log(error);
  return (
    <>
      <DownAngleInnerPage
        title={<FormattedMessage id={"targeted-assistance"} />}
      />
      <motion.section
        className="targeted-assistance"
        variants={variantsSection}
        initial="hidden"
        animate="visible"
      >
        <Container>
          <section className="content text-center mt-0 mb-5">
            {data ? (
              <Row>
                {currItemsTargetedAssistance.map((data) => (
                  <TargetedAssistance key={data.nid} data={data} />
                ))}
                <div style={{ width: "100%" }} />
                {data.length > 8 && (
                  <Pagination
                    className="mt-3"
                    count={Math.ceil(itemsTargetedAssistance.length / 8)}
                    color="primary"
                    onChange={handleChangeTargetedAssistance}
                    showFirstButton
                    showLastButton
                    shape="rounded"
                  />
                )}
              </Row>
            ) : (
              <Row>{scetetonList}</Row>
            )}
          </section>
          <div className="mt-4 mb-4 text-center">
            <Tooltip
              title={context.locale === "ru-RU" ? "На главную" : "To the main"}
              TransitionComponent={Zoom}
              arrow
            >
              <button>
                <Link to={""}>
                  <HomeIcon color="primary" />
                </Link>
              </button>
            </Tooltip>
            <Tooltip
              title={
                context.locale === "ru-RU" ? "На предыдущую" : "To the previous"
              }
              TransitionComponent={Zoom}
              arrow
            >
              <button onClick={() => goBack()}>
                <ReplyIcon color="primary" />
              </button>
            </Tooltip>
          </div>
        </Container>
      </motion.section>
    </>
  );
};

export default PageTargetedAssistance;
